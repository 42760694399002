/**
 * 运行时数据，不需要持久化
 */

import { deepClone } from '@/utils/util.js';
import CoreData from '@/libs/runtime/coreData.js';

const RuntimeData = {

    // cloneMap结构如下：
    // actorId（或spriteId） => clone代码段列表
    cloneMap: {},

    // 用户自定义代码段
    // userFuncMap结构如下：
    // {
    //     userFuncName: {
    //         code: '',
    //         argNames: '',
    //     }
    // }
    userFuncMap: {},

    // 每次运行程序前，从CoreData同步最新的数据信息（当前数据名称、状态、初值等，包括变量和列表等）
    dataMap: {},

    /**
     * 初始化
     */
    reset() {
        this.cloneMap = {};
        this.userFuncMap = {};
        this.resetDataMap();
    },

    /**
     * 克隆
     */
    addCloneCode(actorId, code) {
        if (!this.cloneMap[actorId]) {
            this.cloneMap[actorId] = [];
        }
        this.cloneMap[actorId].push(code);
    },
    getCloneCode(actorId) {
        if (this.cloneMap[actorId]) {
            return this.cloneMap[actorId];
        }
        return null;
    },


    /**
     * 用户自定义函数
     */
    setUserFuncCode(userFuncName, code) {
        if (!this.userFuncMap[userFuncName]) {
            this.userFuncMap[userFuncName] = { code: null, argNames: [] };
        }
        this.userFuncMap[userFuncName].code = code;
    },
    getUserFuncCode(userFuncName) {
        if (this.userFuncMap[userFuncName]) {
            return this.userFuncMap[userFuncName].code;
        }
        return null;
    },
    // 函数参数
    setUserFuncArgNames(userFuncName, argNames) {
        if (!this.userFuncMap[userFuncName]) {
            this.userFuncMap[userFuncName] = { code: null, argNames: [] };
        }
        this.userFuncMap[userFuncName].argNames = argNames;
    },
    getUserFuncArgNames(userFuncName) {
        if (this.userFuncMap[userFuncName]) {
            return this.userFuncMap[userFuncName].argNames;
        }
        return [];
    },


    /**
     * 变量
     * TODO: @@@@@
     */
    getGlobalVars() {
        // let vars = [];
        
    },
    getActorVars(actorId) {

    },
    // 用于在egret中获取当前需要显示的变量数据（程序运行时）
    getShowDatas() {
        let datas = [];
        for (let id in this.dataMap) {
            if (this.dataMap[id].type == "" && this.dataMap[id].isShow) {
                datas.push(this.dataMap[id]);
            }
        }
        return datas;
    },



    // 每次运行程序前，从CoreData同步最新的数据信息（当前数据名称、状态、初值等，包括变量和列表等）
    resetDataMap() {
        delete this.dataMap;
        this.dataMap = null;

        this.dataMap = deepClone(CoreData.getDataMap());
    },



    /**
     * 变量命令
     */
    getVar(varId) {
        // let result = 0;
        let result = "";
        // if (this.dataMap.hasOwnProperty(varId)) {
        if (Object.prototype.hasOwnProperty.call(this.dataMap, varId)) {
            let data = this.dataMap[varId];
            if (data.type == "") {
                return data.value;
            }
        }
        return result;
    },
    setVarTo(actorId, varId, value) {
        if (Object.prototype.hasOwnProperty.call(this.dataMap, varId)) {
            let data = this.dataMap[varId];
            if (data.type == "") {
                data.value = value;

                window['setDataAreaData'](actorId, varId, data);
            }
        }
        window.stageInstance.showAllVars();
    },
    changeVarBy(actorId, varId, value) {
        if (Object.prototype.hasOwnProperty.call(this.dataMap, varId)) {
            let data = this.dataMap[varId];
            if (data.type == "") {
                data.value += value;

                window['setDataAreaData'](actorId, varId, data);
            }
        }
        window.stageInstance.showAllVars();
    },



    /**
     * 列表命令
     */
    getList(listId) {
        let result = "";
        if (Object.prototype.hasOwnProperty.call(this.dataMap, listId)) {
            let data = this.dataMap[listId];
            if (data.type == "list") {
                for (let i = 0; i < data.value.length; i++) {
                    result += data.value[i] + " ";
                }
                result.trim();
            }
        }
        return result;
    },
    listPush(actorId, listId, item) {
        if (Object.prototype.hasOwnProperty.call(this.dataMap, listId)) {
            let data = this.dataMap[listId];
            if (data && data.type == "list") {
                data.value.push(item);

                window['setDataAreaData'](actorId, listId, data);
            }
        }
    },
    listRemove(actorId, listId, index) {
        if (Object.prototype.hasOwnProperty.call(this.dataMap, listId)) {
            let data = this.dataMap[listId];
            if (data.type == "list") {
                if (index > 0 && index <= data.value.length) {
                    data.value.splice(index - 1, 1);
                }

                window['setDataAreaData'](actorId, listId, data);
            }
        }
    },
    listRemoveAll(actorId, listId) {
        if (Object.prototype.hasOwnProperty.call(this.dataMap, listId)) {
            let data = this.dataMap[listId];
            if (data.type == "list") {
                data.value.splice(0, data.value.length);

                window['setDataAreaData'](actorId, listId, data);
            }
        }
    },
    listInsert(actorId, listId, index, item) {
        if (Object.prototype.hasOwnProperty.call(this.dataMap, listId)) {
            let data = this.dataMap[listId];
            if (data.type == "list") {
                let pos = index - 1;
                if (pos < 0) {
                    pos = 0;
                }
                if (pos > data.value.length) {
                    pos = data.value.length;
                }
                data.value.splice(pos, 0, item);

                window['setDataAreaData'](actorId, listId, data);
            }
        }
    },
    listReplace(actorId, listId, index, item) {
        if (Object.prototype.hasOwnProperty.call(this.dataMap, listId)) {
            let data = this.dataMap[listId];
            if (data.type == "list") {
                let pos = index - 1;
                if (pos < 0) {
                    pos = 0;
                }
                if (pos > data.value.length) {
                    pos = data.value.length;
                }
                data.value.splice(pos, 1, item);

                window['setDataAreaData'](actorId, listId, data);
            }
        }
    },
    listGetItem(listId, index) {
        if (Object.prototype.hasOwnProperty.call(this.dataMap, listId)) {
            let data = this.dataMap[listId];
            if (data.type == "list") {
                let pos = index - 1;
                if (pos < 0 || pos >= data.value.length) {
                    return "";
                }
                return data.value[pos];
            }
        }
    },
    listGetIndex(listId, item) {
        if (Object.prototype.hasOwnProperty.call(this.dataMap, listId)) {
            let data = this.dataMap[listId];
            if (data.type == "list") {
                let index = data.value.indexOf(item);
                if (index == -1) {
                    return 0;
                }
                return index + 1;
            }
        }
    },
    listLength(listId) {
        if (Object.prototype.hasOwnProperty.call(this.dataMap, listId)) {
            let data = this.dataMap[listId];
            if (data.type == "list") {
                return data.value.length;
            }
        }
    },
    listContains(listId, item) {
        if (Object.prototype.hasOwnProperty.call(this.dataMap, listId)) {
            let data = this.dataMap[listId];
            if (data.type == "list") {
                return data.value.includes(item);
            }
        }
    }
}

export default RuntimeData;
window.RuntimeData = RuntimeData;
