'use strict';

// import '@/libs/common/howler/howler_2_0_15.min.js';
// import '@/libs/common/tone/p5.Tone.min.js';
import { Howl } from 'howler';

/* eslint-disable no-undef */

const SoundInstance = {

    // 用于保存sound实例
    sounds : {
        // soundId: sound
    },
    piano : null,

    init() {
        this.piano = new Tone.Sampler({
            'A0': 'A0.mp3',
            'C1': 'C1.mp3',
            'D#1': 'Ds1.mp3',
            'F#1': 'Fs1.mp3',
            'A1': 'A1.mp3',
            'C2': 'C2.mp3',
            'D#2': 'Ds2.mp3',
            'F#2': 'Fs2.mp3',
            'A2': 'A2.mp3',
            'C3': 'C3.mp3',
            'D#3': 'Ds3.mp3',
            'F#3': 'Fs3.mp3',
            'A3': 'A3.mp3',
            'C4': 'C4.mp3',
            'D#4': 'Ds4.mp3',
            'F#4': 'Fs4.mp3',
            'A4': 'A4.mp3',
            'C5': 'C5.mp3',
            'D#5': 'Ds5.mp3',
            'F#5': 'Fs5.mp3',
            'A5': 'A5.mp3',
            'C6': 'C6.mp3',
            'D#6': 'Ds6.mp3',
            'F#6': 'Fs6.mp3',
            'A6': 'A6.mp3',
            'C7': 'C7.mp3',
            'D#7': 'Ds7.mp3',
            'F#7': 'Fs7.mp3',
            'A7': 'A7.mp3',
            'C8': 'C8.mp3'
        }, {
            'release': 1,
            'baseUrl': 'https://assets.koocoding.com/assets/sounds/piano/'
        }).toMaster();
    },

    // 用于展示区获取sound实例
    // 暂时不用了
    load(src) {
        return new Howl({
            src: [src],
            onload: function() {
                // console.log('on load');
            },
            onplay: function() {
                // console.log('on play');
            },
            onstop: function() {
                // console.log('on stop');
            },
            onend: function() {
                // console.log('on end');
            },
            onplayerror: function () {
                // console.log('on play error');
            }
        });
    },
    // 用于媒体库及设置框中的声音播放
    play(sound, callback) {
        var curSound = this.sounds[sound.id];
        if (curSound) {
            // if (curSound.playing(sound.id)) {
            //     curSound.stop();
            // } else {
            //     curSound.play();
            // }
            curSound.play();

        } else {
            var soundSrc = 'https://assets.koocoding.com/assets/sounds/' + sound.id + '.' + sound.ext;
            var newSound = new Howl({
                src: [soundSrc],
                onload: function () {
                    newSound.play();
                },
                // 点击stop时
                onstop: function () {
                    if (callback) {
                        callback();
                    }
                },
                // 播放完成时
                onend: function () {
                    if (callback) {
                        callback();
                    }
                }
            });
            this.sounds[sound.id] = newSound;
        }
    },
    stop(soundId) {
        var curSound = this.sounds[soundId];
        if (curSound) {
            if (curSound.playing(soundId)) {
                curSound.stop();
            }
            // } else {
            //     curSound.play();
            // }
        }
    },
    stopAll() {
        for (var soundId in this.sounds) {
            if (this.sounds[soundId]) {
                this.sounds[soundId].stop();
            }
        }
    },
    playNote() {
        // console.log('Got playNote');
        // var sampler  = new Tone.Sampler({
        //     "C1" : "https://assets.koocoding.com/test/C1.mp3",
        // }).toMaster();
        //
        // sampler.triggerAttack("C1");

        // var synth = new Tone.Synth().toMaster();
        // // synth.triggerAttackRelease("C1", "8n");
        // // synth.triggerAttackRelease("C4#", "8n");
        // // synth.triggerAttackRelease("D4", "8n");
        // synth.triggerAttackRelease("E4#1", "8n");
        // // synth.triggerAttackRelease("C#", "8n");

        // var players = new Tone.Players({
        //     low: 'https://tonejs.github.io/examples/audio/casio/C2.mp3',
        //     high: 'https://tonejs.github.io/examples/audio/casio/A2.mp3',
        // }, () => {
        //     console.log(players.loaded);
        //     console.log('got players');
        //     players.get('low').start()
        //     players.get('high').start('+0.5')
        // }).toMaster()

        //
        // if (!SoundInstance.piano) {
        //     SoundInstance.piano = new Tone.Sampler({
        //         // "C1" : "https://assets.koocoding.com/test/C1.mp3",
        //         'A0': 'A0.[mp3|ogg]',
        //         'C1': 'C1.[mp3|ogg]',
        //         'D#1': 'Ds1.[mp3|ogg]',
        //         'F#1': 'Fs1.[mp3|ogg]',
        //         'A1': 'A1.[mp3|ogg]',
        //         'C2': 'C2.[mp3|ogg]',
        //         'D#2': 'Ds2.[mp3|ogg]',
        //         'F#2': 'Fs2.[mp3|ogg]',
        //         'A2': 'A2.[mp3|ogg]',
        //         'C3': 'C3.[mp3|ogg]',
        //         'D#3': 'Ds3.[mp3|ogg]',
        //         'F#3': 'Fs3.[mp3|ogg]',
        //         'A3': 'A3.[mp3|ogg]',
        //         'C4': 'C4.[mp3|ogg]',
        //         'D#4': 'Ds4.[mp3|ogg]',
        //         'F#4': 'Fs4.[mp3|ogg]',
        //         'A4': 'A4.[mp3|ogg]',
        //         'C5': 'C5.[mp3|ogg]',
        //         'D#5': 'Ds5.[mp3|ogg]',
        //         'F#5': 'Fs5.[mp3|ogg]',
        //         'A5': 'A5.[mp3|ogg]',
        //         'C6': 'C6.[mp3|ogg]',
        //         'D#6': 'Ds6.[mp3|ogg]',
        //         'F#6': 'Fs6.[mp3|ogg]',
        //         'A6': 'A6.[mp3|ogg]',
        //         'C7': 'C7.[mp3|ogg]',
        //         'D#7': 'Ds7.[mp3|ogg]',
        //         'F#7': 'Fs7.[mp3|ogg]',
        //         'A7': 'A7.[mp3|ogg]',
        //         'C8': 'C8.[mp3|ogg]'
        //     }, {
        //         'release': 1,
        //         'baseUrl': 'https://assets.koocoding.com/assets/sounds/piano/'
        //     }).toMaster();
        //
        //     // Tone.Buffer.on("load", function(){
        //     //     // this.element.addClass("Loaded");
        //     //     console.log('load~~~~');
        //     // }.bind(this));
        // }

        this.piano.triggerAttackRelease("C1");
    }

    // SoundInstance.init();
}

export default SoundInstance;
window.SoundInstance = SoundInstance;
