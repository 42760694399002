<template>
    <!--添加角色窗口-->
    <div v-show="isShowConfigWindow" class="configureWindow">
        <div class="screen-mask" @click="closeConfigureWindow()"></div>
        <div id="idConfigureWindow" class="window">
            <div class="window-header">
                <div class="header-left">
                    <div
                        class="tab-name"
                        v-bind:class="{ active: curTab == 0 }"
                        @click="switchTab(0)"
                    >
                        {{ tabTitle1 }}
                    </div>
                    <div
                        class="tab-name"
                        v-bind:class="{ active: curTab == 1 }"
                        @click="switchTab(1)"
                    >
                        {{ tabTitle2 }}
                    </div>
                </div>
                <div class="window-close" @click="closeConfigureWindow()">
                    <img src="@/assets/images/ide/close_white.png" />
                </div>
            </div>
            <div class="window-grid">
                <div class="window-body">
                    <div class="window-left">
                        <div class="avatar">
                            <img
                                :src="curActor.src"
                                v-if="curActor.src != ''"
                            />
                        </div>
                        <div class="name">{{ curActor.name }}</div>
                    </div>

                    <div class="window-right">
                        <div class="content" v-if="curTab == 0">
                            <div class="add-content">
                                <div
                                    class="add-row"
                                    @click="openMediaLibWindow('actor', 'true')"
                                >
                                    <img src="@/assets/images/ide/add.svg" />
                                    <div class="label">
                                        {{ addCostumeTitle }}
                                    </div>
                                </div>
                            </div>
                            <div class="row"
                                v-for="(costume, index) in propData.costumes"
                                v-bind:key="index"
                                v-bind:class="{selected:propData.meta.curCostumeIndex == index}"
                                @click="setCostumeById(costume.id, index)"
                            >
                                <div class="left">
                                    <div class="index-wrapper">
                                        <span class="index-bg">  
                                            <span class="index-num"> {{ index + 1}} </span>  
                                        </span>
                                    </div>
                                    <div class="avatar">
                                        <img :src="getImgUrl2(costume.id)" />
                                    </div>
                                    <div class="name">{{ costume.name }}</div>
                                </div>
                                <div class="right">
                                    <img
                                        class="up"
                                        src="@/assets/images/ide/up.svg"
                                        @click.stop="swapCostume('up', index)"
                                    />
                                    <img
                                        class="down"
                                        src="@/assets/images/ide/down.svg"
                                        @click.stop="swapCostume('down', index)"
                                    />
                                    <img
                                        class="delete"
                                        src="@/assets/images/ide/delete.svg"
                                        @click.stop="
                                            deleteCostume(costume.id, index)
                                        "
                                        v-show="
                                            propData.isBackdrop ||
                                            propData.costumes.length > 1
                                        "
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="content" v-else>
                            <div class="add-content">
                                <div
                                    class="add-row"
                                    @click="openMediaLibWindow('sound')"
                                >
                                    <img src="@/assets/images/ide/add.svg" />
                                    <div class="label">{{ addSoundTitle }}</div>
                                </div>
                            </div>
                            <div class="row" v-for="sound in propData.sounds" v-bind:key="sound.id">
                                <div
                                    class="left"
                                    @click.stop="playPauseSound(sound)"
                                >
                                    <div class="sound">
                                        <img src="@/assets/images/ide/sound.svg" />
                                    </div>
                                    <div class="name">{{ sound.name }}</div>
                                    <div class="playHint">
                                        <img
                                            src="@/assets/images/ide/sound_pause.svg"
                                            v-if="curSoundId == sound.id"
                                        />
                                        <img src="@/assets/images/ide/sound_play.svg" v-else />
                                    </div>
                                </div>
                                <div class="right">
                                    <img
                                        class="delete"
                                        src="@/assets/images/ide/delete.svg"
                                        @click.stop="deleteSound(sound.id)"
                                    />
                                    <div class="padding-right"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import CoreData from '@/libs/runtime/coreData.js';
import SoundInstance from '@/libs/common/sound.js';

import { mapGetters } from "vuex";
import { getImgUrl2 } from "@/utils/util";

export default {
    name: "configure",
    props: [],
    data: function () {
        return {
            curTab: 0,
            tabTitle2: "声音",
            curIndex: 0,
            curSoundId: "",
        };
    },
    watch: {
        isShowConfigWindow: function() {
            if (this.isShowConfigWindow) {
                window.addEventListener('keydown', this.keyDown)
            } else {
                window.removeEventListener('keydown', this.keyDown)
            }
        }
    },
    computed: {
        ...mapGetters([
            "curActor", 
            "propData", 
            "ide",
            "isShowConfigWindow"
        ]),
        tabTitle1() {
            if (this.propData.isBackdrop) {
                return "背景";
            }
            return "造型";
        },
        addCostumeTitle() {
            if (this.propData.isBackdrop) {
                return "添加一个背景";
            }
            return "为角色添加一个造型";
        },
        addSoundTitle() {
            if (this.propData.isBackdrop) {
                return "为背景添加一个声音";
            }
            return "为角色添加一个声音";
        },
    },
    methods: {
        getImgUrl2,
        switchTab(tab) {
            this.curTab = tab;
        },
        closeConfigureWindow() {
            this.curTab = 0;
            this.curSoundId = "";

            this.$store.commit("web/ShowWindow", {
                name: "CONFIG",
                isShow: false,
            });
            this.$store.commit("web/SetIsShowSetting", false);

            // 关闭所有正在播放的声音
            SoundInstance.stopAll();
        },
        openMediaLibWindow(type, showCostume = 'false') {
            if (type == "actor" && this.curActor.id == "backdrop") {
                type = "bg";
            }
            // 先隐藏设置窗口
            this.$store.commit("web/ShowWindow", {
                name: "CONFIG",
                isShow: false,
            });
            // 再打开资料库窗口
            this.$store.commit("web/ShowWindow", {
                name: "MEDIA_LIB",
                isShow: true,
                type: type,
                showCostume: showCostume,
            });
        },

        // 造型相关
        setCostumeById(costumeId, index) {
            // let curData = CoreData.getCurData();
            if (this.propData.isBackdrop) {
                let backdrop = CoreData.getCurBackdrop();
                backdrop.meta["curCostumeId"] = costumeId;
                backdrop.meta["curCostumeIndex"] = index;

                this.propData.meta = backdrop.meta;

                // let curCostume = curData.backdrop.costumes[curData.backdrop.meta['curCostumeIndex']];
                let costume = CoreData.getCurCostume("backdrop");
                if (costume) {
                    // 更新角色区
                    this.$store.commit("web/SetActorAreaData", {
                        id: "backdrop",
                        src: getImgUrl2(costume.id),
                    });
                    // 设置新的当前角色
                    this.$store.commit("web/SetCurActor", "backdrop");
                }

                // 更新展示区
                window.backdropInstance.setCostumeById(costumeId);

            } else {
                // let actor = curData.actors[this.curActor.id];
                let actor = CoreData.getCurActor(this.curActor.id);
                if (actor) {
                    actor.meta["curCostumeId"] = costumeId;
                    actor.meta["curCostumeIndex"] = index;

                    this.propData.meta = actor.meta;

                    // let curCostume = actor.costumes[actor.meta['curCostumeIndex']];
                    let costume = CoreData.getCurCostume(this.curActor.id);
                    if (costume) {
                        // 更新角色区
                        this.$store.commit("web/SetActorAreaData", {
                            id: this.curActor.id,
                            src: getImgUrl2(costume.id),
                        });
                        // 设置新的当前角色
                        this.$store.commit("web/SetCurActor", this.curActor.id);
                    }

                    // 更新展示区
                    window.spriteInstance.setCostumeById(
                        this.curActor.id,
                        costumeId
                    );
                }
            }
        },
        swapCostume(type, index) {
            let curData = CoreData.getCurData();
            let costumes = null;

            if (this.propData.isBackdrop) {
                costumes = curData.backdrop.costumes;
            } else {
                let actor = curData.actors[this.curActor.id];
                if (actor) {
                    costumes = actor.costumes;
                }
            }
            if (!costumes) {
                return;
            }

            if (type == "up") {
                if (index <= 0) {
                    // 已经在最上面了
                    return;
                }
                let tmpCostume = costumes[index - 1];
                Vue.set(costumes, index - 1, costumes[index]);
                Vue.set(costumes, index, tmpCostume);
            } else if (type == "down") {
                if (index >= costumes.length - 1) {
                    // 已经在最下面了
                    return;
                }
                let tmpCostume = costumes[index + 1];
                Vue.set(costumes, index + 1, costumes[index]);
                Vue.set(costumes, index, tmpCostume);
            }

            // 根据curCostumeId重新计算curCostumeIndex
            for (let i = 0; i < costumes.length; i++) {
                if (this.propData.isBackdrop) {
                    if (
                        curData.backdrop.meta["curCostumeId"] == costumes[i].id
                    ) {
                        curData.backdrop.meta["curCostumeIndex"] = i;
                        // 更新设置框数据
                        this.propData.meta = curData.backdrop.meta;
                        this.propData.costumes = curData.backdrop.costumes;
                        this.propData.sounds = curData.backdrop.sounds;
                        // 更新展示区
                        window.backdropInstance.swapCostume(type, index);
                        break;
                    }
                } else {
                    if (
                        curData.actors[this.curActor.id].meta["curCostumeId"] ==
                        costumes[i].id
                    ) {
                        curData.actors[this.curActor.id].meta[
                            "curCostumeIndex"
                        ] = i;
                        // 更新设置框数据
                        this.propData.meta =
                            curData.actors[this.curActor.id].meta;
                        this.propData.costumes =
                            curData.actors[this.curActor.id].costumes;
                        this.propData.sounds =
                            curData.actors[this.curActor.id].sounds;
                        // 更新展示区
                        window.spriteInstance.swapCostume(
                            this.curActor.id,
                            type,
                            index
                        );
                        break;
                    }
                }
            }
        },
        deleteCostume(costumeId, index) {
            let curData = CoreData.getCurData();
            if (this.propData.isBackdrop) {
                curData.backdrop.costumes.splice(index, 1);

                // 如果删除的是当前选中Actor，将当前数组中第一位设置为Active元素
                if (costumeId == curData.backdrop.meta["curCostumeId"]) {
                    curData.backdrop.meta["curCostumeIndex"] = 0;
                    if (curData.backdrop.costumes.length >= 1) {
                        curData.backdrop.meta["curCostumeId"] =
                            curData.backdrop.costumes[0].id;
                    } else {
                        curData.backdrop.meta["curCostumeId"] = "";

                        this.$store.commit("web/SetActorAreaData", {
                            id: "backdrop",
                            src: "",
                        });
                    }
                } else {
                    // 如果删除的不是当前选中的Actor，根据当前ActorId，重置Index
                    for (let i = 0; i < curData.backdrop.costumes.length; i++) {
                        if (
                            curData.backdrop.meta["curCostumeId"] ==
                            curData.backdrop.costumes[i].id
                        ) {
                            curData.backdrop.meta["curCostumeIndex"] = i;
                            break;
                        }
                    }
                }

                // 更新显示数据
                this.$store.commit("web/SetPropData", {
                    isBackdrop: true,
                    meta: curData.backdrop.meta,
                    costumes: curData.backdrop.costumes,
                    sounds: curData.backdrop.sounds,
                });

                // 更新角色区图片
                let src = "";
                let curCostume =
                    curData.backdrop.costumes[
                        curData.backdrop.meta["curCostumeIndex"]
                    ];
                if (curCostume) {
                    src = getImgUrl2(curCostume.id);
                }
                this.$store.commit("web/SetActorAreaData", {
                    id: "backdrop",
                    src: src,
                });

                // 更新展示区
                window.backdropInstance.deleteCostume(costumeId);
            } else {
                let actor = curData.actors[this.curActor.id];
                if (actor) {
                    actor.costumes.splice(index, 1);

                    if (costumeId == actor.meta["curCostumeId"]) {
                        actor.meta["curCostumeIndex"] = 0;
                        if (actor.costumes.length >= 1) {
                            actor.meta["curCostumeId"] = actor.costumes[0].id;
                        } else {
                            actor.meta["curCostumeId"] = "";
                        }
                    } else {
                        // 如果删除的不是当前选中的Actor，根据当前ActorId，重置Index
                        for (let i = 0; i < actor.costumes.length; i++) {
                            if (
                                actor.meta["curCostumeId"] ==
                                actor.costumes[i].id
                            ) {
                                actor.meta["curCostumeIndex"] = i;
                                break;
                            }
                        }
                    }

                    // 更新显示数据
                    this.$store.commit("web/SetPropData", {
                        isBackdrop: false,
                        meta: actor.meta,
                        costumes: actor.costumes,
                        sounds: actor.sounds,
                    });

                    // 设置框中，当前对象
                    let curCostume =
                        actor.costumes[actor.meta["curCostumeIndex"]];
                    if (curCostume) {
                        // 更新角色区图片
                        this.$store.commit("web/SetActorAreaData", {
                            id: this.curActor.id,
                            src: getImgUrl2(curCostume.id),
                        });
                    }

                    // 更新展示区
                    window.spriteInstance.deleteCostume(
                        this.curActor.id,
                        costumeId
                    );
                }
            }
        },
        // 声音相关
        deleteSound(soundId) {
            if (this.curSoundId == soundId) {
                this.curSoundId = "";
            }

            let curData = CoreData.getCurData();
            if (this.curActor.id == "backdrop") {
                if (curData.backdrop.sounds.length <= 0) {
                    return;
                }
                let index = 0;
                for (let i = 0; i < curData.backdrop.sounds.length; i++) {
                    if (soundId == curData.backdrop.sounds[i].id) {
                        index = i;
                        break;
                    }
                }
                curData.backdrop.sounds.splice(index, 1);

                // 更新展示区
                window.backdropInstance.deleteSound(soundId);
            } else {
                let actor = curData.actors[this.curActor.id];
                if (actor) {
                    if (actor.sounds.length <= 0) {
                        return;
                    }
                    let index = 0;
                    for (let i = 0; i < actor.sounds.length; i++) {
                        if (soundId == actor.sounds[i].id) {
                            index = i;
                            break;
                        }
                    }
                    actor.sounds.splice(index, 1);

                    // 更新展示区
                    window.spriteInstance.deleteSound(
                        this.curActor.id,
                        soundId
                    );
                }
            }
        },
        soundStopHandler() {
            this.curSoundId = "";
        },
        playPauseSound(sound) {
            if (this.curSoundId != "") {
                this.curSoundId = "";
                SoundInstance.stopAll();
            } else {
                this.curSoundId = sound.id;
                SoundInstance.play(sound, this.soundStopHandler);
            }
        },
        keyDown(e) {
            // e.preventDefault()
            if (e.key == 'Escape') {
                this.closeConfigureWindow()
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.configureWindow {
    .screen-mask {
        position: fixed;
        z-index: 2000;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.7);
    }

    .window {
        position: fixed;
        z-index: 2001;
        width: 750px;
        top: 50%;
        left: 50%;
        margin-top: -250px;
        margin-left: -375px;

        .window-header {
            height: 50px;
            padding: 0 10px;
            //width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            background-color: #a5a2a0;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
            cursor: pointer;

            .header-left {
                display: flex;
                justify-content: flex-start;

                .tab-name {
                    width: 80px;
                    height: 35px;
                    margin-right: 1px;
                    padding: 5px 0 5px 0;
                    font-size: 18px;
                    color: #ffffff;
                    text-align: center;
                    vertical-align: middle;
                    //background-color: #888a85;
                    background-color: #a5a2a0;
                    border-top-left-radius: 8px;
                    border-top-right-radius: 8px;

                    &.active {
                        background-color: #ebebeb;
                        color: #515151;
                    }
                }
                .tab-name:hover {
                    cursor: pointer;
                }
            }

            .window-close {
                margin-right: 10px;
                img {
                    height: 30px;
                    width: 30px;
                    background-color: #383838;
                    border-radius: 15px;
                    border: 3px solid #f9f9f9;
                    padding: 3px;
                }
            }
            .window-close:hover {
                cursor: pointer;
            }
        }

        .window-grid {
            background-color: #a5a2a0;
            padding: 0 10px 10px 10px;
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;

            .window-body {
                height: 430px;
                width: 100%;
                // border-top: 1px solid #e0e0e0;
                color: #707070;
                display: flex;
                justify-content: flex-start;

                background-color: #ebebeb;
                border-bottom-left-radius: 15px;
                border-bottom-right-radius: 15px;

                .window-left {
                    width: 160px;
                    min-width: 160px;
                    height: 100%;
                    //border-right: 1px solid #b8b8b8;
                    //padding: 5px 0;

                    .avatar {
                        margin: 30px auto 10px auto;
                        height: 100px;
                        width: 100px;
                        background: #ffffff;
                        background-image: linear-gradient(
                                45deg,
                                #efefef 25%,
                                transparent 0,
                                transparent 75%,
                                #efefef 0
                            ),
                            linear-gradient(
                                45deg,
                                #efefef 25%,
                                transparent 0,
                                transparent 75%,
                                #efefef 0
                            );
                        background-position: 0 0, 15px 15px;
                        background-size: 30px 30px;

                        img {
                            height: 100%;
                            width: 100%;
                            object-fit: contain;
                        }
                    }
                    .name {
                        color: #515151;
                        font-weight: bold;
                        text-align: center;
                    }
                }

                .window-right {
                    height: 100%;
                    width: 100%;
                    border-left: 1px solid #e0e0e0;
                    display: flex;
                    justify-content: flex-start;
                    overflow-y: auto;

                    .padding {
                        height: 15px;
                        width: 100%;
                    }

                    .prop-content {
                        //display: flex;
                        //flex-wrap: wrap;
                        margin: 20px 0 0 50px;
                        font-size: 17px;
                        color: #515151;

                        .row {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            height: 50px;

                            .label {
                                margin-right: 25px;
                                font-weight: bold;
                            }
                            .name {
                                margin-right: 5px;
                            }
                            .value {
                                margin-right: 5px;
                            }
                            .separate {
                                width: 15px;
                            }
                            .digit2 {
                                width: 40px !important;
                            }
                            input[type="number"] {
                                outline: none;
                                border: 1px solid #dbdbdb;
                                border-radius: 15px;
                                width: 50px;
                                height: 30px;
                                font-size: 16px;
                                padding: 0 5px 0 10px;
                                text-align: center;
                                margin-right: 5px;
                            }
                            input:disabled {
                                background-color: #e0e0e0;
                                text-align: center;
                            }
                            input[type="radio"] {
                                width: 20px;
                                height: 20px;
                                margin-right: 5px;
                            }
                        }
                    }

                    .content {
                        font-size: 17px;
                        color: #515151;
                        width: 100%;

                        .add-content {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                            border-bottom: solid 1px #dbdbdb;
                            // padding: 15px 0;
                            height: 80px;

                            .add-row {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                img {
                                    height: 25px;
                                    width: 25px;
                                    margin-right: 10px;
                                }
                                .label {
                                    color: #515151;
                                    font-weight: bold;
                                }
                            }
                            .add-row:hover {
                                cursor: pointer;
                            }
                        }

                        .row {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            //height: 50px;
                            width: 100%;
                            border-bottom: solid 1px #dbdbdb;
                            padding: 10px 0;
                            margin: 0;
                            cursor: pointer;

                            &.selected {
                                background-color: #fbfafa;
                            }

                            .left {
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;

                                .index-wrapper {
                                    width: 70px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                }
                                .index-bg {
                                    border-radius: 50%;
                                    height: 25px;    
                                    width: 25px;    
                                    display: inline-block;    
                                    background: #777;      
                                    vertical-align: middle;
                                    text-align: center;
                                }
                                .index-num {
                                    display: block;    
                                    color: #FFFFFF;    
                                    height: 25px;    
                                    line-height: 25px;    
                                    text-align: center;
                                    font-size: 15px;
                                    font-weight: bold;
                                }

                                .avatar {
                                    height: 50px;
                                    width: 50px;
                                    margin-right: 15px;
                                    img {
                                        height: 100%;
                                        width: 100%;
                                        object-fit: contain;
                                    }
                                }
                                .name {
                                    color: #515151;
                                    font-size: 16px;
                                    font-weight: bold;
                                }

                                .sound {
                                    height: 50px;
                                    width: 50px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    margin-right: 5px;
                                    img {
                                        height: 25px;
                                        width: 25px;
                                    }
                                }

                                .playHint {
                                    height: 50px;
                                    width: 50px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    margin-left: 5px;
                                    img {
                                        height: 25px;
                                        width: 25px;
                                    }
                                }
                            }
                            //.left:hover {
                            //  cursor: pointer;
                            //}

                            .right {
                                display: flex;
                                justify-content: flex-start;
                                margin-right: 10px;
                                .up {
                                    height: 25px;
                                    width: 25px;
                                    margin-right: 5px;
                                }
                                .up:hover {
                                    cursor: pointer;
                                }
                                .down {
                                    height: 25px;
                                    width: 25px;
                                    margin-right: 5px;
                                }
                                .down:hover {
                                    cursor: pointer;
                                }
                                .delete {
                                    height: 25px;
                                    width: 25px;
                                    margin-right: 5px;
                                }
                                .delete:hover {
                                    cursor: pointer;
                                }
                                .padding-right {
                                    width: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>