// import '@/libs/common/soundMgr.js'

import '@/libs/runtime/coreData.js'
import '@/libs/runtime/nativeHelpers.js'
import '@/libs/runtime/asyncRunner.js'
import '@/libs/runtime/runtimeData.js'
import '@/libs/runtime/runner.js'
import '@/libs/runtime/codeInterpreter.js'

import '@/libs/ide/hooker.js'
import '@/libs/ide/ide.js'

// import '@/libs/blocks/blockly_compressed_vertical.js'
// import '@/libs/blocks/blocks_compressed.js'
// import '@/libs/blocks/blocks_compressed_vertical.js'
// import '@/libs/blocks/javascript_compressed.js'
// import '@/libs/blocks/msg/js/en.js'
