<template>
    <!--添加角色窗口-->
    <div v-show="isShowMediaWindow" class="medialibWindow">
        <div class="screen-mask" @click="closeAddActorWindow"></div>
        <div class="window">
            <div class="window-header">
                <div class="header-left">
                    <div class="title">素材库</div>
                    <div class="search">
                        <input type="text" placeholder="输入名称，查找喜欢的素材" class="text" 
                            v-model="searchKeyword"
                            @keyup="throttle" />
                        <!-- <div class="btn-search">
                            <img src="@/assets/images/ide/search.png">
                        </div> -->
                        <v-btn
                            fab
                            elevation="0"
                            x-small
                            color="#f5f5f5"
                            width="25"
                            height="25"
                            @click.stop="search"
                            v-show="searchKeyword.length == 0"
                        >
                            <v-icon color="grey darken-1"> mdi-magnify </v-icon>
                        </v-btn>
                        <v-btn
                            fab
                            elevation="0"
                            x-small
                            color="#f5f5f5"
                            width="25"
                            height="25"
                            @click.stop="searchReset"
                            v-show="searchKeyword.length > 0"
                        >
                            <v-icon color="grey darken-1"> mdi-close </v-icon>
                        </v-btn>
                    </div>
                </div>
                <div class="page" v-if="page.pageCount > 1">
                    <div class="num"
                         v-for="n in page.pageCount"
                         v-bind:key="n"
                         v-bind:class="{active: n == page.pageIndex}"
                         @click.stop="loadCategoryData(curCategory, n)">
                        {{n}}
                    </div>
                </div>
                <div class="window-close">
                    <img src="@/assets/images/ide/close_white.png" @click="closeAddActorWindow">
                </div>
            </div>
            <div class="window-body">
                <div class="window-left">
                    <div class="category" v-bind:class="{active: curCategory == 'character'}" @click="switchCategory('character')"
                         v-show="curMediaLibType == 'all' || curMediaLibType == 'actor'">
                        <div class="avatar"></div>
                        <div class="name">人物</div>
                    </div>
                    <div class="category" v-bind:class="{active: curCategory == 'creature'}" @click="switchCategory('creature')"
                         v-show="curMediaLibType == 'all' || curMediaLibType == 'actor'">
                        <div class="avatar"></div>
                        <div class="name">动物</div>
                    </div>
                    <div class="category" v-bind:class="{active: curCategory == 'plant'}" @click="switchCategory('plant')"
                         v-show="curMediaLibType == 'all' || curMediaLibType == 'actor'">
                        <div class="avatar"></div>
                        <div class="name">植物</div>
                    </div>
                    <div class="category" v-bind:class="{active: curCategory == 'food'}" @click="switchCategory('food')"
                         v-show="curMediaLibType == 'all' || curMediaLibType == 'actor'">
                        <div class="avatar"></div>
                        <div class="name">食物</div>
                    </div>
                    <div class="category" v-bind:class="{active: curCategory == 'props'}" @click="switchCategory('props')"
                         v-show="curMediaLibType == 'all' || curMediaLibType == 'actor'">
                        <div class="avatar"></div>
                        <div class="name">道具</div>
                    </div>
                    <div class="category" v-bind:class="{active: curCategory == 'letter'}" @click="switchCategory('letter')"
                         v-show="curMediaLibType == 'all' || curMediaLibType == 'actor'">
                        <div class="avatar"></div>
                        <div class="name">字母</div>
                    </div>
                    <div class="category" v-bind:class="{active: curCategory == 'bg_v'}" @click="switchCategory('bg_v')"
                         v-show="curMediaLibType == 'all' || curMediaLibType == 'bg'">
                        <div class="avatar"></div>
                        <div class="name">背景 (纵向)</div>
                    </div>
                    <div class="category" v-bind:class="{active: curCategory == 'bg_h'}" @click="switchCategory('bg_h')"
                         v-show="curMediaLibType == 'all' || curMediaLibType == 'bg'">
                        <div class="avatar"></div>
                        <div class="name">背景 (横向)</div>
                    </div>
                    <div class="category" v-bind:class="{active: curCategory == 'music'}" @click="switchCategory('music')"
                         v-show="curMediaLibType == 'all' || curMediaLibType == 'sound'">
                        <div class="avatar"></div>
                        <div class="name">音乐</div>
                    </div>
                    <div class="category" v-bind:class="{active: curCategory == 'sound'}" @click="switchCategory('sound')"
                         v-show="curMediaLibType == 'all' || curMediaLibType == 'sound'">
                        <div class="avatar"></div>
                        <div class="name">声音</div>
                    </div>
                </div>
                <div class="window-right">
                    <div class="loading" v-if="isLoading">
                        <div class="loadEffect">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                    <div class="content-wrapper" v-else>
                        <!--人物，动物，植物，食物，道具，等等-->
                        <div class="content" 
                            v-if="curCategory == 'character' || 
                                  curCategory == 'creature' || 
                                  curCategory == 'props' ||
                                  curCategory == 'plant' ||
                                  curCategory == 'food' ||
                                  curCategory == 'letter'">
                            <div class="padding"></div>
                            <div v-for="item in resources.imgList" class="item" :key="item.id" @click="addMedia(item)">
                                <div class="item-img" @mouseover="mouseover(item.ids)" @mouseout="mouseout">
                                    <picture class="img" v-if="curImg.id == item.ids[0]">
                                        <source :srcset="getImgUrl2(curImgId, 200, 200) + '/format/webp'" type="image/webp">
                                        <img :src="getImgUrl2(curImgId, 200, 200)" alt="">
                                    </picture>
                                    <picture class="img" v-else>
                                        <source :srcset="getImgUrl2(item.ids[0], 200, 200) + '/format/webp'" type="image/webp">
                                        <img :src="getImgUrl2(item.ids[0], 200, 200)" alt="">
                                    </picture>
                                </div>
                                <div class="name ellipsis">{{item.name}}</div>
                            </div>
                            <div class="content-empty" v-if="resources.imgList.length == 0">
                                没有找到相应素材
                            </div>
                        </div>

                        <!--背景（纵向）-->
                        <div class="content" v-else-if="curCategory == 'bg_v'">
                            <div class="padding"></div>
                            <div v-for="item in resources.imgList" class="bg-v-item" :key="item.id" @click="addMedia(item)">
                                <div class="item-img">
                                    <!-- <img :src="getImgUrl2(item.ids[0], 240, 340)"> -->
                                    <picture class="img">
                                        <source :srcset="getImgUrl2(item.ids[0], 240, 340) + '/format/webp'" type="image/webp">
                                        <img :src="getImgUrl2(item.ids[0], 240, 340)" alt="">
                                    </picture>
                                </div>
                                <div class="name ellipsis">{{item.name}}</div>
                            </div>
                            <div class="content-empty" v-if="resources.imgList.length == 0">
                                没有找到相应素材
                            </div>
                        </div>

                        <!--背景（横向）-->
                        <div class="content" v-else-if="curCategory == 'bg_h'">
                            <div class="padding"></div>
                            <div v-for="item in resources.imgList" class="bg-item" :key="item.id" @click="addMedia(item)">
                                <div class="item-img">
                                    <!-- <img :src="getImgUrl2(item.ids[0], 426, 240)"> -->
                                    <picture class="img">
                                        <source :srcset="getImgUrl2(item.ids[0], 426, 240) + '/format/webp'" type="image/webp">
                                        <img :src="getImgUrl2(item.ids[0], 426, 240)" alt="">
                                    </picture>
                                </div>
                                <div class="name ellipsis">{{item.name}}</div>
                            </div>
                            <div class="content-empty" v-if="resources.imgList.length == 0">
                                没有找到相应素材
                            </div>
                        </div>

                        <!--音乐-->
                        <div class="content" v-else-if="curCategory == 'music'">
                            <div class="padding"></div>
                            <div v-for="(item, idx) in resources.soundList" 
                                 v-bind:key="idx" 
                                 class="music-item" 
                                 v-bind:class="{highlight: curSoundId == item.id}" 
                                 @click.stop="addMedia(item)">
                                <div class="item-img" v-bind:class="'bg-color-' + (idx % 7)"
                                     v-on:mouseenter="setTryingId(item.id)"
                                     v-on:mouseleave="setTryingId('')"
                                     @click.stop="playStopSound(item)">
                                    <div class="img-bg3">
                                        <div class="img-bg2">
                                            <div class="img-bg1">
                                                <span v-if="isTryingId == item.id">
                                                    <span v-if="curSoundId == item.id">
                                                        <img class="music-pause" src="@/assets/images/ide/music_pause.png">
                                                    </span>
                                                    <span v-else>
                                                        <img class="music-play" src="@/assets/images/ide/music_play.png">
                                                    </span>
                                                </span>
                                                <span v-else>
                                                    <img class="music-note" src="@/assets/images/ide/music_note.png">
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="name ellipsis">{{item.name}}</div>
                            </div>
                            <div class="content-empty" v-if="resources.soundList.length == 0">
                                没有找到相应素材
                            </div>
                        </div>

                        <!--声音-->
                        <div class="content" v-else-if="curCategory == 'sound'">
                            <div class="padding"></div>
                            <div v-for="(item, idx) in resources.soundList" 
                                 v-bind:key="idx" 
                                 class="music-item" 
                                 v-bind:class="{highlight: curSoundId == item.id}" 
                                 @click.stop="addMedia(item)">
                                <div class="item-img" v-bind:class="'bg-color-' + (idx % 7)"
                                     v-on:mouseenter="setTryingId(item.id)"
                                     v-on:mouseleave="setTryingId('')"
                                     @click.stop="playStopSound(item)">
                                    <div class="img-bg3">
                                        <div class="img-bg2">
                                            <div class="img-bg1">
                                                 <span v-if="isTryingId == item.id">
                                                    <span v-if="curSoundId == item.id">
                                                        <img class="music-pause" src="@/assets/images/ide/music_pause.png">
                                                    </span>
                                                    <span v-else>
                                                        <img class="music-play" src="@/assets/images/ide/music_play.png">
                                                    </span>
                                                </span>
                                                <span v-else>
                                                    <img class="music-note" src="@/assets/images/ide/music_note.png">
                                                </span>
                                                <!--<audio :id="item.id" preload="none" :src="item.src"></audio>-->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="name ellipsis">{{item.name}}</div>
                            </div>
                            <div class="content-empty" v-if="resources.soundList.length == 0">
                                没有找到相应素材
                            </div>
                        </div>

                        <!--分页栏-->
                        <!--<div class="padding"></div>-->
                        <!--<div class="page" v-if="page.pageCount > 1">-->
                            <!--<div class="num"-->
                                 <!--v-for="n in page.pageCount"-->
                                 <!--v-bind:class="{active: n == page.pageIndex}"-->
                                 <!--@click.stop="loadCategoryData(curCategory, n)">-->
                                <!--{{n}}-->
                            <!--</div>-->
                        <!--</div>-->

                        <!--底部补齐-->
                        <div class="padding"></div>
                        <div class="padding"></div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CoreData from '@/libs/runtime/coreData.js';
    import Ide from '@/libs/ide/ide.js';
    import SoundInstance from '@/libs/common/sound.js';

    import { mapGetters } from 'vuex';
    import { getResources } from '@/api/web'
    import { getImgUrl2 } from '@/utils/util'

    export default {
        name: 'mediaLib',
        props: [
        ],
        data: function () {
            return {
                isLoading: false,
                curCategory: 'character',
                curIndex: 1,
                curImg: {
                    id: '',
                    imgs: [],
                    imgIdx: 0,
                },
                curSoundId: '',
                isTryingId: '',
                resources: {
                    aniList: [],
                    imgList: [],
                    soundList: []
                },
                imgList: [],
                page: {
                    pageCount: 1,
                    pageIndex: 1,
                    //pageSize: 24,
                },
                intervalAutoSwitch: null,

                searchKeyword: "", //搜索内容
                canSearch: true, //搜索限流用
            }
        },
        mounted() {
            this.loadCategoryData(this.curCategory, 1);
        },
        watch: {
            curMediaLibType: function () {
                var category = '';
                switch (this.curMediaLibType) {
                    case 'all':
                        category = 'character';
                        break;
                    case 'actor':
                        category = 'character';
                        break;
                    case 'bg':
                        category = 'bg_v';
                        break;
                    case 'sound':
                        category = 'music';
                        break;
                }
                this.loadCategoryData(category, 1);
            },
            isShowMediaWindow: function() {
                if (this.isShowMediaWindow) {
                    window.addEventListener('keydown', this.keyDown)
                } else {
                    window.removeEventListener('keydown', this.keyDown)
                }
            }
        },
        computed: {
            ...mapGetters([
                'curActor',
                'isShowMediaWindow',
                'isShowSetting',
                'ide',
                'curMediaLibType',
                'curMediaLibShowCostume'
            ]),
            curImgUrl() {
                let curImgId = this.curImg.imgs[this.curImg.imgIdx]
                return 'https://assets.koocoding.com/assets/images/' + curImgId + '/get/?imageView2/2/w/200/h/200';
            },
            curImgId() {
                return this.curImg.imgs[this.curImg.imgIdx]
            }
        },
        methods: {
            getImgUrl2,
            loadCategoryData(category, index, keyword = "") {
                this.curCategory = category;
                this.curIndex = index;
                this.isLoading = true;

                let showCostume = this.curMediaLibShowCostume;
                getResources({category, index, showCostume, keyword}).then(response => {
                    this.resources.aniList = response.data.resList.aniList;
                    this.resources.imgList = response.data.resList.imgList;
                    this.resources.soundList = response.data.resList.soundList;

                    this.page.pageCount = response.data.pageCount;
                    this.page.pageIndex = index;

                    this.isLoading = false;
                })
                .catch(function (err) {
                    console.log(err);
                });
            },
            switchCategory(category) {
                this.loadCategoryData(category, 1, this.searchKeyword);
            },

            addMedia(params) {
                // 添加声音资源
                if (params['type'] == 'music' || params['type'] == 'sound') {
                    this.addSound(params);
                    // 关闭素材选择器
                    this.closeAddActorWindow();
                    return;
                }

                // 添加图片资源，根据isShowSetting来判断是添加“造型”还是添加“角色”
                if (this.isShowSetting) {
                    // 添加造型
                    let imgParams = {
                        id: params['ids'][0],
                        // name: params['name'],
                        name: this.genUniqCostumeName(params['name']),
                        type: params['type'],
                        // aniName: params['aniName'],
                    };
                    this.addCostume(imgParams, false)

                } else {
                    if (this.curCategory == 'bg_v' || this.curCategory == 'bg_h') {
                        // 添加背景
                        let imgParams = {
                            id: params['ids'][0],
                            // name: params['name'],
                            name: this.genUniqCostumeName(params['name']),
                            type: params['type'],
                            // aniName: params['aniName'],
                        };
                        this.addBackdrop(imgParams);

                    } else {
                        // 添加角色
                        let imgParams = {
                            id: params['ids'][0],
                            name: params['name'],
                            type: params['type'],
                            // aniName: params['aniName'],
                        };
                        this.addSprite(imgParams);

                        // 如果角色中本身就含有多个造型，逐一添加到角色中
                        // 此处，角色中的造型是第一次加入到角色中，所以不会存在重名
                        let postfix = "abcdefghijklmnopqrstuvwxyz"
                        if (params['ids'].length > 1) {
                            for (let i = 1; i < params['ids'].length; i++) {
                                imgParams = {
                                    id: params['ids'][i],
                                    name: params['name'] + '-' + postfix[i - 1],
                                    type: params['type'],
                                    // aniName: params['aniName'],
                                };
                                this.addCostume(imgParams, false)
                            }
                        }
                    }
                }
                // 关闭素材选择器
                this.closeAddActorWindow();
            },
            genUniqCostumeName(name) {
                let postfix = "abcdefghijklmnopqrstuvwxyz"
                let index = 0
                let uniqName = name

                let curData = CoreData.getCurData();
                if (this.curActor.id == 'backdrop') {
                    for (let i = 0; i < curData.backdrop.costumes.length; i++) {
                        if (curData.backdrop.costumes[i].name == uniqName) {
                            uniqName = name + '-' + postfix[index]
                            index++
                        }
                    }
                    return uniqName

                } else {
                    let actor = curData.actors[this.curActor.id];
                    if (actor) {
                        for (let i = 0; i < actor.costumes.length; i++) {
                            if (actor.costumes[i].name == uniqName) {
                                uniqName = name + '-' + postfix[index]
                                index++
                            }
                        }
                        return uniqName
                    }
                }
                return uniqName
            },
            // isNewActor 表示当前正在添加/创建角色
            // 此时，不需要egret中实时显示新的costume，也不需要Actor角色区更新图片
            addCostume(params, isNewActor = true) {
                let costume = {
                    id: params['id'],
                    name: params['name'],
                    type: params['type'],
                    // aniName: params['aniName'],
                };

                // 更新到CoreData
                let curData = CoreData.getCurData();
                if (this.curActor.id == 'backdrop') {
                    curData.backdrop.costumes.push(costume);
                    curData.backdrop.meta.curCostumeIndex = curData.backdrop.costumes.length - 1;
                    curData.backdrop.meta.curCostumeId = costume.id;

                    this.$store.commit('web/SetActorAreaData', { id: 'backdrop', src: getImgUrl2(costume.id) });

                    window.backdropInstance.addCostume(costume);

                } else {
                    let actor = curData.actors[this.curActor.id];
                    if (actor) {
                        actor.costumes.push(costume);
                        if (isNewActor) {
                            actor.meta['curCostumeId'] = costume['id'];
                            actor.meta['curCostumeIndex'] = actor.costumes.length - 1;
                        }
                    }

                    if (isNewActor) {
                        window.spriteInstance.addCostume(this.curActor.id, costume, true);
                        this.$store.commit('web/SetActorAreaData', { id: this.curActor.id, src: getImgUrl2(costume.id) });

                    } else {
                        window.spriteInstance.addCostume(this.curActor.id, costume, false);
                    }
                }
            },
            addSprite(params) {
                let curData = CoreData.getCurData();
                // 保存当前角色积木代码
                if (this.curActor.id == 'backdrop') {
                    curData.backdrop.block = Ide.workspace2block();
                } else if (this.curActor.id != '') {
                    if (curData.actors[this.curActor.id]) {
                        curData.actors[this.curActor.id].block = Ide.workspace2block();
                    }
                }
                // 清空积木区
                Ide.clearWorkspace();

                // 创建新actor
                let newActor = CoreData.addActor(params);
                if (newActor) {
                    // 调用引擎接口，新增Sprite
                    window.spriteInstance.addSprite(newActor, true);

                    // 添加角色区 - 角色
                    let costume = CoreData.getCurCostume(newActor.meta.id);
                    if (costume) {
                        this.$store.commit('web/AddActorAreaData', {
                            id: newActor.meta.id,
                            name: newActor.meta.name,
                            src: this.getImgUrl2(costume.id)
                        });
                    }

                    // 设置新的当前角色
                    this.$store.commit('web/SetCurActor', newActor.meta.id);

                    // 更新展示区egret中的当前对象id
                    window.spriteInstance.setCurSprite(newActor.meta.id);

                    // 更新工具栏（如果当前为背景工具栏，切换成角色工具栏）
                    CoreData.updateToolbox('actor');
                    // 为命令栏补充全局变量
                    CoreData.paddingGlobalDatas();
                }
            },
            addBackdrop(params) {
                let curData = CoreData.getCurData();
                if (curData && this.curActor.id != 'backdrop' && this.curActor.id != '') {
                    // 保存当前Sprite的积木信息
                    if (curData.actors[this.curActor.id]) {
                        curData.actors[this.curActor.id].block = Ide.workspace2block();
                    }

                    // 更新代码区
                    this.updateCode(curData);

                    // 选中背景（就是取消其他所有Sprite的选定）
                    window.spriteInstance.selectSprite('backdrop');
                }

                // 更新工具栏（如果当前为角色工具栏，切换成背景工具栏）
                if (this.curActor.id != 'backdrop') {
                    CoreData.updateToolbox('backdrop');
                }

                // 更新当前Actor
                this.$store.commit('web/SetCurActor', 'backdrop');

                // 添加图片
                this.addCostume(params, true);
            },
            updateCode(curData) {
                // 清空积木区
                Ide.clearWorkspace();
                // 恢复背景的积木信息
                Ide.block2workspace(curData.backdrop.block);
                // 将恢复后的积木，同步到代码区
                // console.log('@@@ from updateCode');
                Ide.workspace2code();
            },
            addSound(params) {
                let curData = CoreData.getCurData();
                let sound = {
                    id: params['id'],
                    name: params['name'],
                    type: params['type'],
                    ext: params['ext'],
                };
                if (this.curActor.id == 'backdrop') {
                    curData.backdrop.sounds.push(sound);
                    window.backdropInstance.addSound(sound);

                } else {
                    curData.actors[this.curActor.id].sounds.push(sound);
                    window.spriteInstance.addSound(this.curActor.id, sound);
                }
            },
            closeAddActorWindow() {
                this.stopSound(this.curSoundId);
                
                this.$store.commit('web/ShowWindow', { name: 'MEDIA_LIB', isShow: false });

                if (this.isShowSetting) {
                    this.$store.commit('web/ShowWindow', { name: 'CONFIG', isShow: true });
                }
                
                // 关闭所有正在播放的声音
                SoundInstance.stopAll();
            },
            playSound(sound) {
                this.curSoundId = sound.id;
                SoundInstance.play(sound, this.soundStopHandler);
            },
            stopSound(soundId) {
                this.curSoundId = '';
                SoundInstance.stop(soundId);
            },
            playStopSound(sound) {
                if (this.curSoundId) {
                    this.stopSound(sound.id);
                } else {
                    this.playSound(sound);
                }
            },
            soundStopHandler() {
                this.curSoundId = '';
            },
            setTryingId(id) {
                this.isTryingId = id;
            },
            mouseover(itemId) {
                this.curImg.id = itemId[0]
                this.curImg.imgIdx = 0
                this.curImg.imgs = itemId
                if (this.curMediaLibShowCostume == 'false' && this.curImg.imgs.length > 1) {
                    this.curImg.imgIdx = 1
                    this.intervalAutoSwitch = setInterval(this.autoSwitchImg, 600);
                }
            },
            mouseout() {
                this.curImg.id = ''
                this.curImg.imgIdx = 0
                this.curImg.imgs = []
                clearInterval(this.intervalAutoSwitch);
            },
            autoSwitchImg() {
                if (this.curImg.imgs.length > 1) {
                    this.curImg.imgIdx++;
                    if (this.curImg.imgIdx >= this.curImg.imgs.length) {
                        this.curImg.imgIdx = 0;
                    }
                }
            },
            search() {
                this.isLoading = true

                let category = this.curCategory;
                let index = this.curIndex;
                let showCostume = this.curMediaLibShowCostume;
                let keyword = this.searchKeyword;

                getResources({category, index, showCostume, keyword}).then(response => {
                    this.resources.aniList = response.data.resList.aniList;
                    this.resources.imgList = response.data.resList.imgList;
                    this.resources.soundList = response.data.resList.soundList;

                    this.page.pageCount = response.data.pageCount;
                    this.page.pageIndex = index;

                    this.isLoading = false;
                })
                .catch(function (err) {
                    console.log(err);
                });
            },
            searchReset() {
                this.searchKeyword = "";

                this.search();
            },
            throttle(){
                if (!this.canSearch) {
                    return;
                }
                this.canSearch = false;

                var that = this;
                setTimeout(function() {
                    that.search();
                    that.canSearch = true;
                }, 1000)
            },
            keyDown(e) {
                // e.preventDefault()
                if (e.key == 'Escape') {
                    this.closeAddActorWindow()
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.medialibWindow {
    position: fixed;
    //position: absolute;
    z-index: 2000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 30px 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    .screen-mask {
        position: fixed;
        z-index: 2000;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.7);
    }

    .window {
        position: fixed;
        z-index: 2001;
        top: 50px;
        left: 50px;
        right: 50px;
        bottom: 50px;
        //width: 1200px;
        //height: 680px;

        //max-width: 1200px;
        //min-width: 875px;
        //height: 810px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .window-header {
            width: 100%;
            //min-width: 924px;
            height: 60px;
            border-bottom: 1px solid #b8b8b8;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #ebebeb;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;

            .header-left {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .title {
                    width: 150px;
                    font-size: 20px !important;
                    font-weight: bold;
                    color: #000;
                    text-align: center;
                    vertical-align: middle;
                }

                .search {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border: 1px solid #cccccc;
                    border-radius: 30px;
                    padding: 4px 10px 4px 0;
                    background-color: #f5f5f5;

                    input[type="text"] {
                        outline: none;
                        font-size: 14px;
                        color: #808080;
                        padding: 4px 10px 4px 15px;
                        background-color: #f5f5f5;
                        width: 300px;
                        border-radius: 30px;
                        width: 230px;
                    }
                    input[type="text"]::placeholder {
                        color: #aaa;
                    }

                    .btn-search {
                        height: 18px;
                        width: 18px;
                        cursor: pointer;
                        img {
                            height: 100%;
                            width: 100%;
                        }
                    }
                }
            }

            .page {
                width: 100%;
                height: 50px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-left: 50px;

                .num {
                    height: 32px;
                    width: 32px;
                    font-size: 15px;
                    color: #777;
                    background-color: #f6f6f6;
                    border-radius: 32px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 10px;
                    cursor: pointer;
                    &.active {
                        color: #fff;
                        background-color: #ffaf5e;
                    }
                }
            }

            .window-close {
                margin-right: 10px;
                img {
                    height: 30px;
                    width: 30px;
                    background-color: #383838;
                    border-radius: 15px;
                    border: 3px solid #f9f9f9;
                    padding: 3px;
                    cursor: pointer;
                }
            }
        }

        .window-body {
            // height: 100%;
            flex: 1;
            min-height: 0;
            width: 100%;
            border-top: 1px solid #e0e0e0;
            color: #707070;
            display: flex;
            justify-content: flex-start;
            background: #ebebeb;
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;

            .window-left {
                width: 150px;
                height: 100%;
                border-right: 1px solid #b8b8b8;
                //padding: 5px 0;

                .category {
                    padding: 12px 0;
                    border-top: 1px solid #ffffff;
                    border-bottom: 1px solid #cecece;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    align-content: center;

                    &.active {
                        background: #e8d2ca;
                        border-left: 5px solid #e84e40;
                        border-top: 0;
                        color: #e84e40;
                    }

                    .name {
                        font-size: 17px;
                    }
                }
                .category:hover {
                    cursor: pointer;
                }
            }

            .window-right {
                height: 100%;
                width: 100%;
                flex: 1;
                //min-width: 724px;
                border-left: 1px solid #e0e0e0;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                align-items: center;
                align-content: flex-start;
                overflow-y: auto;
                padding: 0 24px;

                .loading {
                    height: 100%;
                    width: 100%;

                    .loadEffect {
                        width: 100px;
                        height: 100px;
                        position: relative;
                        margin: 0 auto;
                        margin-top: 200px;
                    }
                    .loadEffect span {
                        display: inline-block;
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        background: grey;
                        position: absolute;
                        -webkit-animation: load 1.04s ease infinite;
                    }
                    @-webkit-keyframes load {
                        0% {
                            -webkit-transform: scale(1.2);
                            opacity: 1;
                        }
                        100% {
                            -webkit-transform: scale(0.3);
                            opacity: 0.5;
                        }
                    }
                    .loadEffect span:nth-child(1) {
                        left: 0;
                        top: 50%;
                        margin-top: -10px;
                        -webkit-animation-delay: 0.13s;
                    }
                    .loadEffect span:nth-child(2) {
                        left: 14px;
                        top: 14px;
                        -webkit-animation-delay: 0.26s;
                    }
                    .loadEffect span:nth-child(3) {
                        left: 50%;
                        top: 0;
                        margin-left: -10px;
                        -webkit-animation-delay: 0.39s;
                    }
                    .loadEffect span:nth-child(4) {
                        top: 14px;
                        right: 14px;
                        -webkit-animation-delay: 0.52s;
                    }
                    .loadEffect span:nth-child(5) {
                        right: 0;
                        top: 50%;
                        margin-top: -10px;
                        -webkit-animation-delay: 0.65s;
                    }
                    .loadEffect span:nth-child(6) {
                        right: 14px;
                        bottom: 14px;
                        -webkit-animation-delay: 0.78s;
                    }
                    .loadEffect span:nth-child(7) {
                        bottom: 0;
                        left: 50%;
                        margin-left: -10px;
                        -webkit-animation-delay: 0.91s;
                    }
                    .loadEffect span:nth-child(8) {
                        bottom: 14px;
                        left: 14px;
                        -webkit-animation-delay: 1.04s;
                    }
                }

                .content-wrapper {
                    height: 100%;
                    width: 100%;
                    //min-width: 724px;

                    .padding {
                        height: 15px;
                        width: 100%;
                    }

                    .content {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: flex-start;
                        align-items: flex-start;
                        align-content: flex-start;
                        width: 100%;
                        //min-height: 675px;

                        //.padding {
                        //  height: 15px;
                        //  width: 100%;
                        //}

                        .content-empty {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-top: 30px;
                        }

                        .content-title {
                            width: 100%;
                        }

                        .item-type {
                            color: #666666;
                            width: 100%;
                            margin: 15px 20px 15px 10px;
                            padding-bottom: 8px;
                            font-size: 20px;
                            border-bottom: 1px solid #cccccc;
                        }

                        .item {
                            height: 150px;
                            width: 120px;
                            -webkit-box-shadow: 1px 1px 5px 0 #777777;
                            -moz-box-shadow: 1px 1px 5px 0 #777777;
                            box-shadow: 1px 1px 5px 0 #777777;
                            border-radius: 8px;
                            background: #f9f9f9;
                            margin: 20px 22px 20px 22px;
                            padding: 10px 10px 0 10px;
                            position: relative;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;

                            .item-bg1 {
                                position: absolute;
                                top: -2px;
                                left: -2px;
                                background: #f9f9f9;
                                //border: 1px solid #555555;
                                -webkit-box-shadow: 1px 1px 5px 0 #777777;
                                -moz-box-shadow: 1px 1px 5px 0 #777777;
                                box-shadow: 1px 1px 5px 0 #777777;
                                border-radius: 8px;
                                transform: skew(3deg, -3deg);
                                height: 162px;
                                width: 137px;
                                z-index: -1;
                            }
                            .item-bg2 {
                                position: absolute;
                                top: -2px;
                                left: -2px;
                                background: #f9f9f9;
                                //border: 1px solid #555555;
                                -webkit-box-shadow: 1px 1px 5px 0 #777777;
                                -moz-box-shadow: 1px 1px 5px 0 #777777;
                                box-shadow: 1px 1px 5px 0 #777777;
                                border-radius: 8px;
                                transform: skew(6deg, -6deg);
                                height: 162px;
                                width: 137px;
                                z-index: -2;
                            }

                            .item-img {
                                //background: #e6e6e6;
                                //padding: 5px;
                                height: 100px;
                                width: 100px;
                                display: flex;
                                justify-content: center;
                                overflow: hidden;
                                img {
                                    height: 100%;
                                    width: 100%;
                                    object-fit: contain;
                                }
                            }
                            .name {
                                color: #555555;
                                font-size: 14px;
                                text-align: center;
                                vertical-align: middle;
                                margin-top: 6px;
                            }
                        }
                        .item:hover {
                            background-color: #fff;
                            cursor: pointer;
                        }

                        .bg-item {
                            height: 155px;
                            width: 215px;
                            -webkit-box-shadow: 1px 1px 5px 0 #777777;
                            -moz-box-shadow: 1px 1px 5px 0 #777777;
                            box-shadow: 1px 1px 5px 0 #777777;
                            border-radius: 8px;
                            background: #f9f9f9;
                            //margin: 20px 22px 25px 22px;
                            margin: 20px 16px 20px 16px;
                            padding: 12px 12px 0 12px;
                            position: relative;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;

                            .item-img {
                                //background: #e6e6e6;
                                //padding: 5px;
                                height: 107px;
                                width: 190px;
                                display: flex;
                                justify-content: center;
                                overflow: hidden;
                                img {
                                    height: auto;
                                    width: 100%;
                                    object-fit: cover;
                                }
                            }
                            .name {
                                color: #555555;
                                font-size: 14px;
                                text-align: center;
                                vertical-align: middle;
                                margin-top: 6px;
                            }
                        }
                        .bg-item:hover {
                            background-color: #fff;
                            cursor: pointer;
                        }

                        .bg-v-item {
                            height: 240px;
                            width: 160px;
                            -webkit-box-shadow: 1px 1px 5px 0 #777777;
                            -moz-box-shadow: 1px 1px 5px 0 #777777;
                            box-shadow: 1px 1px 5px 0 #777777;
                            border-radius: 8px;
                            background: #f9f9f9;
                            //margin: 20px 22px 25px 22px;
                            margin: 20px 18px 20px 18px;
                            padding: 12px 12px 0 12px;
                            position: relative;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;

                            .item-img {
                                //background: #e6e6e6;
                                //padding: 5px;
                                height: 193px;
                                width: 135px;
                                display: flex;
                                justify-content: center;
                                overflow: hidden;
                                img {
                                    height: 100%;
                                    width: 100%;
                                    object-fit: cover;
                                }
                            }
                            .name {
                                color: #555555;
                                font-size: 14px;
                                text-align: center;
                                vertical-align: middle;
                                margin-top: 6px;
                            }
                        }
                        .bg-v-item:hover {
                            background-color: #fff;
                            cursor: pointer;
                        }

                        .music-item {
                            height: 155px;
                            width: 140px;
                            -webkit-box-shadow: 1px 1px 5px 0 #777777;
                            -moz-box-shadow: 1px 1px 5px 0 #777777;
                            box-shadow: 1px 1px 5px 0 #777777;
                            border-radius: 8px;
                            background: #f9f9f9;
                            margin: 20px 22px 20px 22px;
                            padding: 10px 10px 0 10px;
                            position: relative;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;

                            &.highlight {
                                background-color: #ffffff;
                            }

                            .item-img {
                                height: 105px;
                                width: 120px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                overflow: hidden;
                                background-color: #ffaf5e;

                                &.bg-color-0 {
                                    background-color: #ffaf5e;
                                }
                                &.bg-color-1 {
                                    background-color: #f57b6f;
                                }
                                &.bg-color-2 {
                                    background-color: #f47dbb;
                                }
                                &.bg-color-3 {
                                    background-color: #b979f7;
                                }
                                &.bg-color-4 {
                                    background-color: #73d843;
                                }
                                &.bg-color-5 {
                                    background-color: #3fd6b4;
                                }
                                &.bg-color-6 {
                                    background-color: #fbd55c;
                                }

                                .music-note {
                                    // position: absolute;
                                    height: 40px;
                                    width: auto;
                                    margin-right: 2px;
                                }
                                .music-play {
                                    // position: absolute;
                                    height: 40px;
                                    width: auto;
                                    margin-left: 1px;
                                    //visibility: hidden;
                                }
                                .music-pause {
                                    // position: absolute;
                                    height: 40px;
                                    width: auto;
                                    margin-left: 1px;
                                }

                                .img-bg1 {
                                    height: 60px;
                                    width: 60px;
                                    border: 1px solid #e5e5e5;
                                    border-radius: 60px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    span {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                    }
                                }
                                .img-bg2 {
                                    height: 83px;
                                    width: 83px;
                                    border: 1px solid rgba(229, 229, 229, 0.8);
                                    border-radius: 83px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                }
                                .img-bg3 {
                                    height: 110px;
                                    width: 110px;
                                    border: 1px solid rgba(229, 229, 229, 0.6);
                                    border-radius: 110px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                }
                            }
                            //.item-img:hover {
                            //  .music-note {
                            //    visibility: hidden;
                            //  }
                            //  .music-play {
                            //    visibility: visible;
                            //  }
                            //}
                            .name {
                                color: #555555;
                                font-size: 14px;
                                text-align: center;
                                vertical-align: middle;
                                margin-top: 10px;
                            }
                        }
                        .music-item:hover {
                            background-color: #fff;
                            cursor: pointer;
                        }
                    }

                    .page {
                        width: 100%;
                        height: 40px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .num {
                            height: 32px;
                            width: 32px;
                            font-size: 15px;
                            color: #777;
                            background-color: #f6f6f6;
                            border-radius: 32px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-right: 10px;
                            &.active {
                                color: #fff;
                                background-color: #ffaf5e;
                            }
                        }
                        .num:hover {
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}
</style>