import CoreData from '@/libs/runtime/coreData.js';
import RuntimeData from '@/libs/runtime/runtimeData.js';
import Runner from '@/libs/runtime/runner.js';
import Ide from '@/libs/ide/ide.js';
import AsyncInterpreterRunner from '@/libs/runtime/asyncRunner.js';

/* eslint-disable no-undef */
const CodeInterpreter = {

    idCounter: 1000,
    isRunning: true,

    // 函数
    createInterpreterInitializer: (jobId, actorId) => {

        var motion = {
            move: function(steps) {
                Motion.move(jobId, steps);
            },
            turnLeft: function(degree) {
                Motion.turnLeft(jobId, degree);
            },
            turnRight: function(degree) {
                Motion.turnRight(jobId, degree);
            },
            moveTo: function(x, y) {
                Motion.moveTo(jobId, x, y);
            },
            setX: function(x) {
                Motion.setX(jobId, x);
            },
            setY: function(y) {
                Motion.setY(jobId, y);
            },
            changeXBy: function(dx) {
                Motion.changeXBy(jobId, dx);
            },
            changeYBy: function(dy) {
                Motion.changeYBy(jobId, dy);
            },
            pointDirection: function(direction) {
                Motion.pointDirection(jobId, direction);
            },
            getX: function() {
                return Motion.getX(jobId);
            },
            getY: function() {
                return Motion.getY(jobId);
            },
            getDirection: function() {
                return Motion.getDirection(jobId);
            },
            pointTowards: function(towards) {
                Motion.pointTowards(jobId, towards);
            },
            goto: function(to) {
                Motion.goto(jobId, to);
            },
            glide: function (sec, x, y) {
                Motion.glide(jobId, sec, x, y);
            },
            glideTo: function (sec, to) {
                Motion.glideTo(jobId, sec, to);
            },
            glideSteps: function (sec, steps) {
                Motion.glideSteps(jobId, sec, steps);
            },
            bounceOnEdge: function () {
                Motion.bounceOnEdge(jobId);
            },
            setRotationStyle: function(style) {
                Motion.setRotationStyle(jobId, style);
            }
        };
    
        var looks = {
            show: function() {
                Looks.show(jobId);
            },
            hide: function() {
                Looks.hide(jobId);
            },
            changeEffect: function (effect, val) {
                Looks.changeEffect(jobId, effect, val)
            },
            setEffect: function (effect, val) {
                Looks.setEffect(jobId, effect, val);
            },
            resetEffects: function () {
                Looks.resetEffects(jobId);
            },
            say: function (msg) {
                Looks.say(jobId, msg);
            },
            sayFor: function (msg, sec) {
                Looks.sayFor(jobId, msg, sec);
            },
            think: function (msg) {
                Looks.think(jobId, msg);
            },
            thinkFor: function (msg, sec) {
                Looks.thinkFor(jobId, msg, sec);
            },
            nextCostume: function() {
                Looks.nextCostume(jobId);
            },
            setCostume: function(costumeName) {
                Looks.setCostume(jobId, costumeName);
                // console.log('Got setCostume: ' + costumeName)
            },
            gotoFrontBack: function(position) {
                Looks.gotoFrontBack(jobId, position);
            },
            moveLayers: function(direction, num) {
                Looks.moveLayers(jobId, direction, num);
            },
            getCostumeInfo: function(infoType) {
                return Looks.getCostumeInfo(jobId, infoType);
            },
            getSize: function() {
                return Looks.getSize(jobId);
            },
            setSize: function(size) {
                Looks.setSize(jobId, size);
            },
            changeSizeBy: function(size) {
                Looks.changeSizeBy(jobId, size);
            },
            nextBackdrop: function() {
                Looks.nextBackdrop();
            },
            setBackdrop: function(backdropName) {
                Looks.setBackdrop(backdropName);
            },
            getBackdropInfo: function(infoType) {
                return Looks.getBackdropInfo(infoType);
            }
        };
    
        var canvas = {
            clear: function() {
                Canvas.clear();
            },
            penDown: function() {
                Canvas.penDown(jobId);
            },
            penUp: function() {
                Canvas.penUp(jobId);
            },
            setColor: function(color) {
                Canvas.setColor(jobId, color)
            },
            setPenEffect: function(effect, num) {
                Canvas.setPenEffect(jobId, effect, num);
            },
            changePenEffect: function(effect, num) {
                Canvas.changePenEffect(jobId, effect, num);
            },
            setPenSize: function(size) {
                Canvas.setPenSize(jobId, size);
            },
            changePenSize: function(size) {
                Canvas.changePenSize(jobId, size);
            },
            stamp: function() {
                Canvas.stamp(jobId);
            }
        };

        var sensing = {
            isTouching: function(obj) {
                return Sensing.isTouching(jobId, obj);
            },
            isTouchingColor: function(color) {
                return Sensing.isTouchingColor(jobId, color);
            },
            getDistance: function(obj) {
                return Sensing.getDistance(jobId, obj);
            },
            askAndWait: function(question) {
                Sensing.askAndWait(jobId, question);
            },
            getAnswer: function() {
                return Sensing.getAnswer();
            },
            isKeyPressed: function(key) {
                return Sensing.isKeyPressed(key);
            },
            isMouseDown: function () {
                return Sensing.isMouseDown();
            },
            getMouseX: function() {
                return Sensing.getMouseX();
            },
            getMouseY: function() {
                return Sensing.getMouseY();
            },
            setDragMode: function(mode) {
                Sensing.setDragMode(jobId, mode);
            },
            getDateTime: function(timeType) {
                return Sensing.getDateTime(timeType);
            },
            getDateSince2000: function() {
                return Sensing.getDateSince2000();
            },
            getTimer: function() {
                return Sensing.getTimer(jobId);
            },
            resetTimer: function() {
                Sensing.resetTimer(jobId);
            },
            getObjectProperty: function(obj, property) {
                return Sensing.getObjectProperty(jobId, obj, property);
            }
        };
    
        var sound = {
            play: function(soundName) {
                Sounds.play(jobId, soundName);
            },
            playUntilDone(soundName) {
                Sounds.playUntilDone(jobId, soundName);
            },
            stopAll: function() {
                Sounds.stopAll(jobId);
            },
            setVolume: function(volume) {
                Sounds.setVolume(jobId, volume);
            },
            getVolume: function() {
                return Sounds.getVolume(jobId);
            }
        };
    
        var data = {
            /**
             * 变量相关
             */
            getVar: function(varId, varName) {
                return RuntimeData.getVar(varId);
            },
            setVarTo: function(varId, varName, value) {
                RuntimeData.setVarTo(actorId, varId, value);
            },
            changeVarBy: function(varId, varName, value) {
                RuntimeData.changeVarBy(actorId, varId, value);
            },
            showVar: function(varId, varName) {
                CoreData.setShowStatus(varId, true);
            },
            hideVar: function(varId, varName) {
                CoreData.setShowStatus(varId, false);
            },

            /**
             * 列表相关
             */
            getList: function(listId, listName) {
                return RuntimeData.getList(listId);
            },
            push: function(listId, listName, item) {
                RuntimeData.listPush(actorId, listId, item);
            },
            remove: function(listId, listName, index) {
                RuntimeData.listRemove(actorId, listId, index);
            },
            removeAll: function(listId, listName) {
                RuntimeData.listRemoveAll(actorId, listId);
            },
            insert: function(listId, listName, index, item) {
                RuntimeData.listInsert(actorId, listId, index, item);
            },
            replace: function(listId, listName, index, item) {
                RuntimeData.listReplace(actorId, listId, index, item);
            },
            getItem: function(listId, listName, index) {
                return RuntimeData.listGetItem(listId, index);
            },
            getIndex: function(listId, listName, item) {
                return RuntimeData.listGetIndex(listId, item);
            },
            length: function(listId, listName) {
                return RuntimeData.listLength(listId);
            },
            contains: function(listId, listName, item) {
                return RuntimeData.listContains(listId, item);
            }
        };
    
        var randomRange = function(from, to) {
            let min = Math.ceil(from);
            let max = Math.floor(to);
            return Math.floor(Math.random() * (max - min + 1)) + min; //含最大值，含最小值 
        };

        // 操作operator
        var math = {
            add: function(num1, num2) {
                var n1 = parseFloat(num1);
                var n2 = parseFloat(num2);
                if (isNaN(n1)) {
                    n1 = 0;
                }
                if (isNaN(n2)) {
                    n2 = 0;
                }
                return n1 + n2;
            },
            subtract: function(num1, num2) {
                var n1 = parseFloat(num1);
                var n2 = parseFloat(num2);
                if (isNaN(n1)) {
                    n1 = 0;
                }
                if (isNaN(n2)) {
                    n2 = 0;
                }
                return n1 - n2;
            },
            multiply: function(num1, num2) {
                var n1 = parseFloat(num1);
                var n2 = parseFloat(num2);
                if (isNaN(n1)) {
                    n1 = 0;
                }
                if (isNaN(n2)) {
                    n2 = 0;
                }
                return n1 * n2;
            },
            divide: function(num1, num2) {
                var n1 = parseFloat(num1);
                var n2 = parseFloat(num2);
                if (isNaN(n1)) {
                    n1 = 0;
                }
                if (isNaN(n2)) {
                    n2 = 0;
                }
                if (n2 == 0) {
                    return '';
                }
                return n1 / n2;
            },
            mod: function(num1, num2) {
                var n1 = parseFloat(num1);
                var n2 = parseFloat(num2);
                if (isNaN(n1)) {
                    n1 = 0;
                }
                if (isNaN(n2)) {
                    n2 = 0;
                }
                return n1 % n2;
            },
            round: function(num) {
                return Math.round(num)
            },
            abs: function(num) {
                return Math.abs(num);
            },
            floor: function(num) {
                return Math.floor(num);
            },
            ceil: function(num) {
                return Math.ceil(num);
            },
            sqrt: function(num) {
                return Math.sqrt(num);
            },
            sin: function(degree) {
                // return Math.round(Math.sin(degree * (Math.PI / 180)) * 100) / 100;
                return Math.sin(degree * (Math.PI / 180)).toFixed(2);
            },
            cos: function(degree) {
                // return Math.round(Math.cos(degree * (Math.PI / 180)) * 100) / 100;
                return Math.cos(degree * (Math.PI / 180)).toFixed(2);
            },
            tan: function(degree) {
                // return Math.round(Math.tan(degree * (Math.PI / 180)) * 100) / 100;
                return Math.tan(degree * (Math.PI / 180)).toFixed(2);
            },
            asin: function(radians) {
                // return Math.round(Math.asin(radians) * (180 / Math.PI) * 100) / 100;
                return (Math.asin(radians) * (180 / Math.PI)).toFixed(2);
            },
            acos: function(radians) {
                // return Math.round(Math.acos(radians) * (180 / Math.PI) * 100) / 100;
                return (Math.acos(radians) * (180 / Math.PI)).toFixed(2);
            },
            atan: function(radians) {
                // return Math.round(Math.atan(radians) * (180 / Math.PI) * 100) / 100;
                return (Math.atan(radians) * (180 / Math.PI)).toFixed(2);
            },
            ln: function(num) {
                // return Math.round(Math.log(num) * 100) / 100;
                return Math.log(num).toFixed(2);
            },
            log: function(num) {
                // code = 'this.math.log(' + num + ') / Math.LN10';
                // return Math.round((Math.log(num) / Math.LN10) * 100) / 100;
                return (Math.log(num) / Math.LN10).toFixed(2);
            },
            exp: function(num) {
                // return Math.round(Math.exp(num) * 100) / 100;
                return Math.exp(num).toFixed(2);
            },
            pow: function(base, num) {
                // return Math.round(Math.pow(base, num) * 100) / 100;
                return Math.pow(base, num).toFixed(2);
            },
        };

    
        var charAt = function(str, index) {
            if (str == '') {
                return '';
            }
            if (index <= 0 || index > str.length) {
                return '';
            }
            return str.charAt(index - 1);
        };


        var broadcast = function(msg) {
            EventMgr.dispatchEvent('event_broadcast_received', '', msg);
        };
        var broadcastAndWait = function(msg) {
            // 先把当前线程暂停
            CodeInterpreter.startWaiting(jobId);

            EventMgr.dispatchEvent('event_broadcast_received', '', msg, jobId);
        };

    
        var clone = function(spriteName) {
            Control.clone(jobId, spriteName);
        };
    
        var stopCloneSelf = function() {
            Control.stopClone(jobId);
            CodeInterpreter.stopJob(jobId);
        };
    
        var wait = function(secs) {
            Common.wait(jobId, secs);
        };

        // var waitUntil = function(blockId, condition) {
        //     // 如果条件成立，直接通过
        //     if (condition == true) {
        //         return ;
        //     }

        //     // 如果当前条件不成立，循环测试条件，直到true

        //     // 此处获取block有问题！！！
        //     // 如果不是当前角色的话，Ide.workspace取不到对应的block
        //     // 解决方案：
        //     // 将waitUntil逻辑转换成control_repeat_until的对等逻辑即可

        //     var block = Ide.workspace.getBlockById(blockId);
        //     var originCondition = Blockly.JavaScript.valueToCode(block, 'CONDITION',Blockly.JavaScript.ORDER_NONE) || 'false';
        //     // 因为eval作用域的问题，去掉this.
        //     originCondition = originCondition.replace(/^this./, '');

        //     var checkCondition = function() {
        //         if (CodeInterpreter.isRunning) {
        //             if (eval(originCondition) == false) {
        //                 // CodeInterpreter.startWaiting(id);
        //                 CodeInterpreter.startWaiting(jobId);
    
        //                 window.setTimeout(checkCondition, 10);
    
        //             } else {
        //                 // CodeInterpreter.endWaiting(id);
        //                 CodeInterpreter.endWaiting(jobId);
        //             }
        //         }
        //     };
        //     checkCondition();
        // };

        var stop = function(option) {
            if (option == 'all') {
                CodeInterpreter.stop();
            } else if (option == 'this script') {
                CodeInterpreter.stopJob(jobId);
            } else if (option == 'other scripts in sprite') {
                CodeInterpreter.stopOtherJobs(jobId, actorId);
            }
        };
    
        // 自定义函数
        var userFunc = function(procName, ...args) {
            // 先把当前线程暂停
            CodeInterpreter.startWaiting(jobId);
            // 取用户自定义函数代码，并执行，执行完成后，会重新唤醒当前进程
            CodeInterpreter.callUserFunc(jobId, actorId, procName, args);
        };
        // 获取自定义函数的参数（使用函数名）
        // 说明：每个函数对应一个线程job，每个函数中的参数不能重复
        var getArg = function(argName) {
            return Runner.getArg(jobId, argName);
        }

        var highlightBlock = function(blockId) {
            // return Ide.workspace.glowStack(blockId, true);
            // return Ide.workspace.highlightBlock(blockId, true);
            // var blocks = Ide.workspace.getAllBlocks();
            // console.log(blocks);
            // for (var i = blocks.length - 1; i >= 0; i--) {
            //     console.log(blocks[i]);
            //     if (blocks[i].startHat_ || !blocks[i].isShadow_) {
            //         Ide.workspace.highlightBlock(blocks[i].id, true);
            //     }
            // }
        };
    
        return function(interpreter, scope, helper) {
            interpreter.setProperty(scope, 'motion', helper.nativeValueToInterpreter(motion));
            interpreter.setProperty(scope, 'looks', helper.nativeValueToInterpreter(looks));
            interpreter.setProperty(scope, 'canvas', helper.nativeValueToInterpreter(canvas));
            interpreter.setProperty(scope, 'sensing', helper.nativeValueToInterpreter(sensing));
            interpreter.setProperty(scope, 'sound', helper.nativeValueToInterpreter(sound));
            interpreter.setProperty(scope, 'data', helper.nativeValueToInterpreter(data));
            interpreter.setProperty(scope, 'math', helper.nativeValueToInterpreter(math));
            // interpreter.setProperty(scope, 'add', helper.nativeValueToInterpreter(add));
            // interpreter.setProperty(scope, 'subtract', helper.nativeValueToInterpreter(subtract));
            // interpreter.setProperty(scope, 'multiply', helper.nativeValueToInterpreter(multiply));
            // interpreter.setProperty(scope, 'divide', helper.nativeValueToInterpreter(divide));
            // interpreter.setProperty(scope, 'mod', helper.nativeValueToInterpreter(mod));

            interpreter.setProperty(scope, 'randomRange', helper.nativeValueToInterpreter(randomRange));
            interpreter.setProperty(scope, 'charAt', helper.nativeValueToInterpreter(charAt));

            interpreter.setProperty(scope, 'broadcast', helper.nativeValueToInterpreter(broadcast));
            interpreter.setProperty(scope, 'broadcastAndWait', helper.nativeValueToInterpreter(broadcastAndWait));
            interpreter.setProperty(scope, 'wait', helper.nativeValueToInterpreter(wait));

            // interpreter.setProperty(scope, 'waitUntil', interpreter.createNativeFunction(waitUntil));
            interpreter.setProperty(scope, 'stop', interpreter.createNativeFunction(stop));
            interpreter.setProperty(scope, 'clone', interpreter.createNativeFunction(clone));
            interpreter.setProperty(scope, 'stopCloneSelf', interpreter.createNativeFunction(stopCloneSelf));

            interpreter.setProperty(scope, 'console', helper.nativeValueToInterpreter(window.console));
            // interpreter.setProperty(scope, 'highlightBlock', interpreter.createNativeFunction(highlightBlock));
            interpreter.setProperty(scope, 'userFunc', helper.nativeValueToInterpreter(userFunc));
            interpreter.setProperty(scope, 'getArg', helper.nativeValueToInterpreter(getArg));
        }
    },
    // 调用用户自定义函数
    // 每个角色范围内的函数名称是唯一的，且不能使用系统保留函数名
    callUserFunc: (jobId, actorId, procName, args) => {
        var userFuncName = actorId + '_' + procName;
        var code = RuntimeData.getUserFuncCode(userFuncName);

        // 此处使用一个全局计数器来生成对应于自定义函数的jobId，避免冲突
        CodeInterpreter.idCounter += parseInt(Math.random() * 10, 10) + 1;
        let newJobId = jobId + '_' + CodeInterpreter.idCounter;

        // 将调用者（caller）的jobId传递给自定义函数的runner，当自定义函数执行结束后，重新唤醒caller线程
        let cloneRunner = new AsyncInterpreterRunner(newJobId, actorId, code, Runner.interpreterInitializer(newJobId, actorId), false, jobId);
        Runner.submit(cloneRunner, newJobId);

        // 将参数绑定到新的线程中
        var names = RuntimeData.getUserFuncArgNames(userFuncName);
        Runner.setArgValues(newJobId, names, args);
    },
    highlightBlocks: (highlight) => {
        var blocks = Ide.workspace.getTopBlocks(true);
    
        // 允许生成代码的顶层模块
        var enabledTopBlocks = {
            'event_whenflagclicked': true,
            'event_whenthisspriteclicked': true,
            'event_whenkeypressed': true,
            'event_whenbroadcastreceived': true,
            'event_whenbackdropswitchesto': true,
            'event_whengreaterthan': true,
            'control_start_as_clone': true,
            'procedures_definition': true,
        };
    
        for (var x = 0, block; blocks[x]; x++) {
            block = blocks[x];
            if (block.type in enabledTopBlocks) {
                // Ide.workspace.highlightBlock(block.id, true);
                Ide.workspace.glowStack(block.id, highlight);
            }
        }
    },
    play: (backdrop, actors) => {
        CodeInterpreter.isRunning = true;
    
        window.stageInstance.hideSelector();
    
        // 1.创建调度器
        Runner.init(CodeInterpreter.createInterpreterInitializer);

        // 处理背景block
        let codes = Ide.workspace2runCode(backdrop.block);
        for (let index in codes) {
            let jobId = 'sprite_' + 'backdrop' + '_' + index;
    
            // 用户自定义函数段
            if (codes[index].type == 'event_user_function') {

                let procName = codes[index].args.procName;
                let userFuncName = 'backdrop' + '_' + procName;
                let argNames = codes[index].args.argNames;

                // 保存函数名列表，用于后续函数调用（call）时绑定实参
                RuntimeData.setUserFuncArgNames(userFuncName, argNames);

                // 保存用户自定义代码段
                RuntimeData.setUserFuncCode(userFuncName, codes[index].code);
            }

            // 注册事件及回调事件
            let isEvent = true;
            if (codes[index].type == 'event_start') {
                isEvent = false;
            } else {
                EventMgr.addEvent(codes[index].type, jobId, codes[index].args);
                isEvent = true;
            }
    
            let runner = new AsyncInterpreterRunner(jobId, 'backdrop', codes[index].code, CodeInterpreter.createInterpreterInitializer(jobId, 'backdrop'), isEvent);
            Runner.submit(runner, jobId);
        }
    
        // 2.从workspace获取代码
        for (let actorId in actors) {
    
            // 3.获取各个Sprite对应的代码，并按照代码入口，将代码切分成不同的线程块
            let codes = Ide.workspace2runCode(actors[actorId].block);
            for (let index in codes) {
                // index用于区分同一个sprite对应的多组积木代码入口
                let jobId = '';
                if (codes[index].type == 'event_start_as_clone') {
                    jobId = 'clone_' + actorId + '_' + index;
                    //保存clone事件
                    RuntimeData.addCloneCode(actorId, codes[index].code);
    
                } else {
                    jobId = 'sprite_' + actorId + '_' + index;
                }

                // 用户自定义函数段
                if (codes[index].type == 'event_user_function') {

                    let procName = codes[index].args.procName;
                    let userFuncName = actorId + '_' + procName;
                    let argNames = codes[index].args.argNames;

                    // 保存函数名列表，用于后续函数调用（call）时绑定实参
                    RuntimeData.setUserFuncArgNames(userFuncName, argNames);

                    // 保存用户自定义代码段
                    RuntimeData.setUserFuncCode(userFuncName, codes[index].code);
                }
    
                // 注册事件及回调事件
                let isEvent = true;
                if (codes[index].type == 'event_start') {
                    isEvent = false;
                } else {
                    EventMgr.addEvent(codes[index].type, jobId, codes[index].args);
                    isEvent = true;
                }
    
                // final.将不同线程块代码放入JS解释器，加入调度器
                let runner = new AsyncInterpreterRunner(jobId, actorId, codes[index].code, CodeInterpreter.createInterpreterInitializer(jobId, actorId), isEvent);
                Runner.submit(runner, jobId);
            }
        }
    
        // 5.运行
        Runner.run(function () {
            // 程序运行完成后主动停止
            CodeInterpreter.autoStop();
        });
    },
    // 内部函数：停止解析器
    stopInterpreter: () => {
        CodeInterpreter.isRunning = false;
        Runner.stop();
    },
    // 程序执行完毕，自动停止
    autoStop: () => {
        // 停止脚本解析器
        CodeInterpreter.stopInterpreter();
        // 更新界面
        window['autoStop']();
    },
    // 用于控制命令：stop
    stop: () => {
        // 停止脚本解析器
        CodeInterpreter.stopInterpreter();
        // 更新界面
        window['stop']();
    },
    // 用于控制命令：stop this script
    stopJob: (jobId) => {
        Runner.stopJob(jobId);
    },
    // 用于控制命令：stop other scripts
    stopOtherJobs: (jobId, actorId) => {
        Runner.stopOtherJobs(jobId, actorId);
    },

    startProcess: (jobId, doneCallback = null) => {
        if (CodeInterpreter.isRunning) {
            if (doneCallback) {
                Runner.setDoneCallback(jobId, doneCallback);
            }
            Runner.setWaiting(jobId, false);
        }
    },

    startCloneProcess: (actorId, cloneId) => {
        let codes = RuntimeData.getCloneCode(actorId);
        for (let i = 0; i < codes.length; i++) {
            let jobId = 'clone_' + actorId + '_' + cloneId + '_' + i;
            let cloneRunner = new AsyncInterpreterRunner(jobId, actorId, codes[i], Runner.interpreterInitializer(jobId, actorId), false);
            Runner.submit(cloneRunner, jobId);
        }
    },

    startAsyncProcess: (jobId) => {
        if (CodeInterpreter.isRunning) {
            Runner.setWaiting(jobId, true);
        }
    },
    endAsyncProcess: (jobId) => {
        if (CodeInterpreter.isRunning) {
            Runner.setWaiting(jobId, false);
        }
    },

    // 同startAsyncProcess
    startWaiting: (jobId) => {
        if (CodeInterpreter.isRunning) {
            Runner.setWaiting(jobId, true);
        }
    },
    // 同endAsyncProcess
    endWaiting: (jobId) => {
        if (CodeInterpreter.isRunning) {
            Runner.setWaiting(jobId, false);
        }
    },
}

export default CodeInterpreter;
window.CodeInterpreter = CodeInterpreter;
