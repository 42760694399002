import NativeHelper from '@/libs/runtime/nativeHelpers.js';

/* eslint-disable no-undef */

function AsyncInterpreterRunner(jobId, actorId, code, initCallback, isEvent, caller = null) {
    this.jobId = jobId;
    this.actorId = actorId;
    this.callerJobId = caller;
    this.code = code;
    this.initCallback = initCallback;
    this.doneCallback = null;
    this.interpreter = new Interpreter(code, this.initInterpreter.bind(this));
    this.stop = false;
    this.isWaiting = isEvent ? true : false;
    this.isEvent = isEvent;
    this.runSpeed = 100; //没有用到

    // this.argNames = [];  //似乎没有用到
    this.argsMap = {};
    // argsMap说明：
    // argsMap在用户调用自定义函数时才能确定具体的参数
    // 结构：
    // argsMap = {
    //     argName1: arg1,
    //     argName2: arg2,
    //     ...
    // }
}

AsyncInterpreterRunner.prototype.initInterpreter = function (interpreter, scope) {
    var helper = new NativeHelper(interpreter);
    this.helper = helper;

    this.asyncWait = interpreter.createObject(interpreter.OBJECT);
    // TODO: Kind of hacky
    this.asyncWait.customArgumentNativeConversion = this.convertAsyncToNativeCallback.bind(this);
    this.asyncResult = interpreter.createObject(interpreter.OBJECT);
    interpreter.setProperty(scope, 'asyncResult', this.asyncResult);
    interpreter.setProperty(scope, 'asyncWait', this.asyncWait);

    if (this.initCallback) {
        this.initCallback(interpreter, scope, helper);
    }
};

// 这个函数似乎没有用上？？？
AsyncInterpreterRunner.prototype.convertAsyncToNativeCallback = function () {
    var that = this;
    var helper = this.helper;
    that.isWaiting = true;
    return function (error, result) {
        var interpreterResult = helper.nativeValueToInterpreter({
            error: error,
            result: result
        });
        helper.interpreter.setProperty(that.asyncResult, 'error',
            helper.nativeValueToInterpreter(error));
        helper.interpreter.setProperty(that.asyncResult, 'result',
            helper.nativeValueToInterpreter(result));

        that.isWaiting = false;
    };
};

// 当前程序段全速运行，因为要统筹多个角色，所有此处没有用到
AsyncInterpreterRunner.prototype.run = function (doneCallback) {
    var that = this;
    function nextStep() {
        if (that.isWaiting) {
            window.setTimeout(nextStep, 0);
        } else if (that.interpreter.step()) {
            window.setTimeout(nextStep, that.runSpeed);
            nextStep();
        } else {
            if (doneCallback) {
                doneCallback();
            }
        }
    }
    nextStep();
};

// 返回false表示当前程序段已经执行完毕
// 返回true表示当前程序段需要继续运行
AsyncInterpreterRunner.prototype.step = function () {
    if (this.stop) {
        return false;
    } else if (this.isWaiting) {
        return true;
    } else if (this.interpreter.step()) {
        return true;
    } else {
        return false;
    }
};

AsyncInterpreterRunner.prototype.setWaiting = function (isWaiting) {
    this.isWaiting = isWaiting;
};

AsyncInterpreterRunner.prototype.setStop = function (stop) {
    this.stop = stop;
};

AsyncInterpreterRunner.prototype.setDoneCallback = function (doneCallback) {
    this.doneCallback = doneCallback;
};
AsyncInterpreterRunner.prototype.doneCall = function () {
    return this.doneCallback;
};

export default AsyncInterpreterRunner;
