/*
 * Runner
 */

import RuntimeData from '@/libs/runtime/runtimeData.js';
import CodeInterpreter from '@/libs/runtime/codeInterpreter.js';
import AsyncInterpreterRunner from '@/libs/runtime/asyncRunner.js';

const Runner = {
    interpreterInitializer: null,
    settings: {
        // 16毫秒对应每秒60帧
        jobDelay: 16,
        // 运行速度: 
        // 1: 慢速
        // 10: 中速(默认)
        // 100: 快速
        // 1000: 非常快
        stepSpeed: 10,
    },
    // 当前帧中要做的任务（job）列表
    queue: [],
    // jobMap中保存的是 jobId => AsyncInterpreterRunner
    jobMap: {},
    isRunning: false,

    init(interpreterInitializer) {
        this.interpreterInitializer = interpreterInitializer;
        this.reset();
    },
    reset() {
        this.queue = [];
        this.jobMap = {};
        this.isRunning = false;
        RuntimeData.reset();
    },
    setSpeed(speed) {
        this.settings.stepSpeed = speed
    },
    submit(runner, jobId) {
        this.queue.push({ 
            runner: runner,
            jobId: jobId,
            isDone: false,  //当前程序段是否已经运行完成
        });
        this.jobMap[jobId] = runner;
    },
    run(doneCallback) {
        this.isRunning = true;
        this._doWork(doneCallback);
    },
    _doWork(doneCallback) {
        if (!this.isRunning) {
            this.queue = []
            this.jobMap = []
            doneCallback();
            return;
        }

        for (let i = 0; i < this.queue.length; i++) {
            // 如果当前程序段已经执行完成，跳过
            let job = this.queue[i]
            if (job.isDone) {
                continue
            }

            // 控制程序运行速度，stepSpeed越大，每帧可以运行的指令越多            
            let stepSpeed = this.settings.stepSpeed
            while (--stepSpeed >= 0) {
                
                // step返回false就是程序执行完成了
                if (!job.runner.step()) {

                    job.isDone = true

                    // 当前程序段执行完毕后，如果是event事件，重新加入到scheduler中，这样sprite才可以监听下一次事件
                    if (job.runner.isEvent) {
                        var jobId = job.runner.jobId;
                        var actorId = job.runner.actorId;
                        var code = job.runner.code;

                        var runner = new AsyncInterpreterRunner(jobId, actorId, code, this.interpreterInitializer(jobId, actorId), true);
                        this.submit(runner, jobId);
                    }

                    // 如果是用户自定义函数执行完成，重新唤醒调用者线程
                    if (job.runner.callerJobId) {
                        // 此处的jobId是当前执行用户自定义函数的线程，不是调用者线程
                        CodeInterpreter.endWaiting(job.runner.callerJobId);
                    }
                    if (job.runner.doneCallback) {
                        job.runner.doneCallback();
                    }

                    // 当前程序段执行完毕，退出
                    break;
                }
            }
        }

        // 准备下一帧
        if (this.isRunning) {
            // Reschedule
            window.setTimeout(this._doWork.bind(this, doneCallback), this.settings.jobDelay);
        }
    },
    stop() {
        this.isRunning = false;

        // 将未完成的job全部弹出
        for (;;) {
            if (!this.queue.shift()) {
                break;
            }
        }
        this.reset();
    },
    stopJob(jobId) {
        if (this.jobMap && this.jobMap[jobId]) {
            this.jobMap[jobId].setStop(true);
            window.stageInstance.stopJob(jobId);
        }
    },
    // 停止当前角色中的其他脚本（job）
    stopOtherJobs(jobId, actorId) {
        // jobId存在，且在运行中，避免重复执行
        if (this.jobMap && this.jobMap[jobId].stop == false) {
            for (var id in this.jobMap) {
                if (id != jobId && this.jobMap[id].actorId == actorId) {
                    this.jobMap[id].setStop(true);
                    window.stageInstance.stopJob(id, actorId);
                }
            }
        }
    },
    setWaiting(jobId, isWaiting) {
        if (this.jobMap[jobId]) {
            this.jobMap[jobId].setWaiting(isWaiting);
        }
    },
    setDoneCallback(jobId, doneCallback) {
        if (this.jobMap[jobId]) {
            this.jobMap[jobId].setDoneCallback(doneCallback);
        }
    },
    getArg(jobId, argName) {
        if (this.jobMap && this.jobMap[jobId]) {
            return this.jobMap[jobId].argsMap[argName];
        }
        return '';
    },
    // 正常来说，values的长度应该和names的长度是一致的
    setArgValues(jobId, names, values) {
        if (this.jobMap && this.jobMap[jobId]) {
            for (var i = 0; i < names.length; i++) {
                this.jobMap[jobId].argsMap[names[i]] = values[i];
            }
        }
    }
}

export default Runner;
